@media (max-width: 768px) {
.iphone {
  background-color: #000212;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.iphone .div-3 {
  background-color: #000212;
  height: 6180px;
  overflow: hidden;
  position: relative;
  width: 390px;
}

.iphone .overlap {
  height: 1431px;
  left: -771px;
  position: absolute;
  top: 2400px;
  width: 1419px;
}

.iphone .group {
  height: 524px;
  left: 771px;
  position: absolute;
  top: 227px;
  width: 390px;
}

.iphone .rectangle-4 {
  background: linear-gradient(
    180deg,
    rgb(0, 2, 18) 0%,
    rgba(0, 2, 18, 0.78) 45%,
    rgba(0, 2, 18, 0) 100%
  );
  height: 731px;
  left: 0;
  position: absolute;
  top: 30px;
  transform: rotate(180.00deg);
  width: 1419px;
}

.iphone .blur {
  height: 932px;
  left: 771px;
  position: absolute;
  top: 15px;
  width: 390px;
}

.iphone .iphone-pro {
  height: 480px;
  left: 851px;
  position: absolute;
  top: 0;
  width: 234px;
}

.iphone .photo-transaction {
  background-color: var(--midnight-indigo);
  border-radius: 16.99px;
  height: 460px;
  left: 11px;
  overflow: hidden;
  position: absolute;
  top: 8px;
  width: 212px;
}

.iphone .iphone-lements {
  height: 852px;
  left: 0;
  position: absolute;
  top: 0;
  width: 393px;
}

.iphone .header-text {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 3px;
  position: relative;
  width: 156.67px;
}

.iphone .p {
  align-self: stretch;
  color: #474747;
  font-family: "Switzer-Regular", Helvetica;
  font-size: 7px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 11.0px;
  position: relative;
  text-align: center;
}

.iphone .amount {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 3px;
  position: relative;
}

.iphone .payment-details {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6.01px;
  position: relative;
  width: 100%;
}

.iphone .payment-detail {
  align-items: flex-start;
  border: 0.5px solid;
  border-color: #ededed;
  border-radius: 3px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 2px;
  padding: 6.01px;
  position: relative;
}

.iphone .silver {
  height: 480px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 234px;
}

.iphone .frame-37 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 816px;
  position: absolute;
  top: 611px;
  width: 300px;
}

.iphone .container {
  align-self: stretch;
  height: 164px;
  position: relative;
  width: 100%;
}

.iphone .overlap-5 {
  height: 164px;
  position: relative;
  width: 300px;
}

.iphone .overlap-group-5 {
  height: 164px;
  left: 0;
  position: absolute;
  top: 0;
  width: 300px;
}

.iphone .receipt-capture-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  left: 0;
  padding: 0px 64.22px 0px 64.2px;
  position: absolute;
  top: 84px;
  width: 300px;
}

.iphone .receipt-capture {
  color: #f4f0ff;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  margin-left: -12.71px;
  margin-right: -12.71px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.iphone .container-2 {
  align-items: center;
  display: flex;
  flex-direction: column;
  left: 0;
  padding: 0px 48.33px 0px 48.31px;
  position: absolute;
  top: 108px;
  width: 300px;
}

.iphone .text-wrapper-22 {
  color: #efedfd99;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  margin-left: -0.32px;
  margin-right: -0.32px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  width: 204px;
}

.iphone .frame-38 {
  height: 30px;
  left: 135px;
  position: absolute;
  top: 35px;
  width: 30px;
}

.iphone .frame-39 {
  height: 30px;
  left: 135px;
  position: absolute;
  top: 28px;
  width: 30px;
}

.iphone .organize-track-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  left: 0;
  padding: 0px 57.5px;
  position: absolute;
  top: 84px;
  width: 300px;
}

.iphone .text-wrapper-23 {
  color: #f4f0ff;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.iphone .container-3 {
  align-items: center;
  display: flex;
  flex-direction: column;
  left: 0;
  padding: 0px 39.2px 0px 39.19px;
  position: absolute;
  top: 108px;
  width: 300px;
}

.iphone .text-wrapper-24 {
  color: #efedfd99;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.iphone .container-4 {
  align-items: center;
  display: flex;
  flex-direction: column;
  left: 0;
  padding: 0px 36.83px 0px 36.81px;
  position: absolute;
  top: 84px;
  width: 300px;
}

.iphone .container-5 {
  align-items: center;
  display: flex;
  flex-direction: column;
  left: 0;
  padding: 0px 34.54px 0px 34.55px;
  position: absolute;
  top: 108px;
  width: 300px;
}

.iphone .frame-40 {
  height: 30px;
  left: 135px;
  position: absolute;
  top: 29px;
  width: 30px;
}

.iphone .container-6 {
  align-items: center;
  display: flex;
  flex-direction: column;
  left: 0;
  padding: 0px 67.6px 0px 67.59px;
  position: absolute;
  top: 84px;
  width: 300px;
}

.iphone .container-7 {
  align-items: center;
  display: flex;
  flex-direction: column;
  left: 0;
  padding: 0px 56.5px 0px 56.48px;
  position: absolute;
  top: 108px;
  width: 300px;
}

.iphone .frame-41 {
  height: 30px;
  left: 135px;
  position: absolute;
  top: 30px;
  width: 30px;
}

.iphone .container-8 {
  align-items: center;
  display: flex;
  flex-direction: column;
  left: 0;
  padding: 0px 94.59px 0px 94.58px;
  position: absolute;
  top: 84px;
  width: 300px;
}

.iphone .text-wrapper-25 {
  color: #f4f0ff;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  margin-left: -18.08px;
  margin-right: -18.08px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.iphone .container-9 {
  align-items: center;
  display: flex;
  flex-direction: column;
  left: 0;
  padding: 0px 88.52px 0px 88.5px;
  position: absolute;
  top: 108px;
  width: 300px;
}

.iphone .text-wrapper-26 {
  color: #efedfd99;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  margin-left: -44.51px;
  margin-right: -44.51px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.iphone .frame-42 {
  height: 30px;
  left: 135px;
  position: absolute;
  top: 32px;
  width: 30px;
}

.iphone .overlap-6 {
  height: 442px;
  left: 0;
  position: absolute;
  top: 0;
  width: 390px;
}

.iphone .frame-43 {
  height: 209px;
  left: 0;
  position: absolute;
  top: 0;
  width: 390px;
}

.iphone .blur-2 {
  height: 442px;
  left: 0;
  mix-blend-mode: screen;
  position: absolute;
  top: 0;
  width: 390px;
}

.iphone .group-2 {
  top: 38px;
  left: 10px; /* Keep a few pixels from the left */
  width: auto; /* Allow width to adjust dynamically if needed */
  max-width: 120px; /* Optional: limit width for smaller devices */
  height: 32px;
  position: fixed;
  transform: none; /* Remove centering transform */
  z-index: 1000; /* Ensure it stays on top */
}

.iphone .container-10 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  left: 0;
  padding: 0px 20px;
  position: absolute;
  top: 115px;
  width: 388px;
}

.iphone .save-time-and-money sup {
  font-size: 0.65em; /* Adjust size as needed */
  vertical-align: top; /* Align with the text */
  line-height: 1; /* Prevent extra spacing issues */
}
.iphone .save-time-and-money {
  align-self: stretch;
  color: #d9d9d9;
  font-family: "Switzer-Medium", Helvetica;
  font-size: 36px;
  font-weight: 500;
  letter-spacing: -1.60px;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
}

.iphone .text-wrapper-27 {
  align-self: stretch;
  color: #b4bcd0;
  font-family: "Switzer-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 28.6px;
  position: relative;
  text-align: center;
}

.iphone .follow {
  align-items: center;
  background-color: #ffffff;
  border-radius: 8px;
  display: inline-flex;
  gap: 10px;
  height: 38px;
  padding: 8px 15px;
  position: relative;
}

.iphone .follow a {
  display: inline-flex; /* Make it behave like a container */
  align-items: center; /* Align items vertically */
  gap: 10px; /* Ensure consistent spacing */
  text-decoration: none; /* Remove underline from the link */
  color: inherit; /* Keep the text color as it was */
}

.iphone .border {
  border: 1px solid;
  border-color: #f3eeff14;
  border-radius: 8px;
  height: 38px;
  left: 0;
  position: absolute;
  top: 0;
  width: 122px;
}

.iphone .SVG {
  height: 16px;
  position: relative;
  width: 16px;
}

.iphone .text-wrapper-28 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(
    180deg,
    rgb(18, 19, 64) 0%,
    rgb(108, 83, 183) 100%
  );
  background-clip: text;
  color: transparent;
  font-family: "Switzer-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.iphone .iphone-pro-mockup {
  height: auto;
  left: auto;
  position: absolute;
  top: 500px;
  width: 100%;
  transform: scale(1.7); /* Zoom in by 20% and center it */
  transform-origin: center; /* Ensures the zoom effect centers around the element */
}

.iphone .frame-44 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 55px;
  left: 0;
  position: absolute;
  top: 900px;
  width: 390px;
}

.iphone .container-11 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  max-width: 1088px;
  padding: 0px 20px;
  position: relative;
  width: 100%;
}

.iphone .overlay-shadow {
  -webkit-backdrop-filter: blur(6px) brightness(100%);
  align-items: center;
  backdrop-filter: blur(6px) brightness(100%);
  background-color: #ffffff01;
  border-radius: 32px;
  box-shadow: inset 0px -7px 11px #a48fff1f;
  display: inline-flex;
  flex: 0 0 auto;
  overflow: hidden;
  padding: 6px 14px 6px 15px;
  position: relative;
  flex-wrap: nowrap; /* Prevent overflow issues */
}

.iphone .container-12 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.iphone .text-wrapper-29 {
  color: #fff;
  font-family: "Inter", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: auto;
}

.iphone .mask-group {
  height: 32px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 97px;
}

.iphone .text-wrapper-30 {
  align-self: stretch;
  color: #f7f8f8;
  font-family: "Switzer-Medium", Helvetica;
  font-size: 36px;
  font-weight: 500;
  letter-spacing: -1.12px;
  line-height: 56px;
  position: relative;
  text-align: center;
}

.iphone .container-13 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16.59px;
  justify-content: center;
  max-width: 752px;
  padding: 0px 22.12px;
  position: relative;
  width: 100%;
}

.iphone .frame-45 {
  height: 330px;
  overflow: hidden;
  position: relative;
  width: 346px;
}

.iphone .overlap-7 {
  height: 724px;
  position: relative;
}

.iphone .background {
  align-items: flex-start;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgb(102, 99, 246) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  border-radius: 33.18px;
  display: flex;
  flex-direction: column;
  left: 0;
  overflow: hidden;
  padding: 22.12px 33.18px 215.19px;
  position: absolute;
  top: 0;
  width: 346px;
}

.iphone .frame-46 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  position: relative;
  width: 100%;
}

.iphone .text-wrapper-31 {
  align-self: stretch;
  color: #f7f8f8;
  font-family: "Switzer-Medium", Helvetica;
  font-size: 21px;
  font-weight: 500;
  letter-spacing: -0.17px;
  line-height: 21.6px;
  margin-top: -1.00px;
  position: relative;
}

.iphone .text-wrapper-32 {
  align-self: stretch;
  color: #b4bcd0;
  font-family: "Switzer-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16.6px;
  position: relative;
}

.iphone .mask-group-2 {
  height: 329px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 346px;
}

.iphone .archive-2 {
  -webkit-backdrop-filter: blur(20.74px) brightness(100%);
  backdrop-filter: blur(20.74px) brightness(100%);
  background: linear-gradient(
    180deg,
    rgba(18, 19, 64, 0.5) 0%,
    rgba(46.69, 49.28, 166, 0) 100%
  );
  border-color: transparent;
  border-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.2) 50.5%,
      rgba(255, 255, 255, 0) 100%
    )
    1;
  border-radius: 35px;
  height: 586px;
  left: 33px;
  position: absolute;
  top: 138px;
  width: 279px;
  display: flex; /* Flexbox for alignment */
  justify-content: center; /* Center horizontally */
  align-items: flex-start; /* Align image to the top */
  overflow: hidden; /* Clip overflowing parts of the image */
}

.iphone .frame-47 {
  height: auto; /* Maintain aspect ratio */
  width: 100%; /* Fit the width of the container */
  max-height: 100%; /* Ensure it doesn't exceed the container's height */
  object-fit: contain; /* Prevent stretching and keep aspect ratio */
}

.iphone .frame-68 {
  height: 334px;
  overflow: hidden;
  position: relative;
  width: 346px;
}

.iphone .overlap-8 {
  height: 722px;
  position: relative;
  top: 7px;
}

.iphone .mask-group-3 {
  height: 327px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 346px;
}

.iphone .archive-3 {
  -webkit-backdrop-filter: blur(20.74px) brightness(100%);
  backdrop-filter: blur(20.74px) brightness(100%);
  background: linear-gradient(
    180deg,
    rgba(18, 19, 64, 0.5) 0%,
    rgba(46.69, 49.28, 166, 0) 100%
  );
  border-color: transparent;
  border-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.2) 50.5%,
      rgba(255, 255, 255, 0) 100%
    )
    1;
  border-radius: 35px;
  height: 586px;
  left: 33px;
  position: absolute;
  top: 136px;
  width: 279px;
  display: flex; /* Flexbox for alignment */
  justify-content: center; /* Center horizontally */
  align-items: flex-start; /* Align content to the top */
  overflow: hidden; /* Clip overflowing parts of the image */
}

.iphone .frame-72 {
  height: auto; /* Maintain aspect ratio */
  width: 100%; /* Fit the width of the container */
  max-height: 100%; /* Ensure it doesn't exceed the container's height */
  object-fit: contain; /* Prevent stretching and keep aspect ratio */
}

.iphone .container-14 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  left: 0;
  padding: 0px 20px;
  position: absolute;
  top: 2000px;
  width: 390px;
}

.iphone .mask-group-4 {
  height: 32px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 88px;
}

.iphone .text-wrapper-39 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  align-self: stretch;
  background: linear-gradient(
    180deg,
    rgb(255, 255, 255) 0%,
    rgba(255, 255, 255, 0.7) 100%
  );
  background-clip: text;
  color: transparent;
  font-family: "Switzer-Regular", Helvetica;
  font-size: 36px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
}

.iphone .text-wrapper-40 {
  align-self: stretch;
  color: #efedfdb2;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  text-align: center;
}

.iphone .overlap-9 {
  height: 672px;
  left: 40px;
  position: absolute;
  top: 3900px;
  width: 310px;
}

.iphone .text-wrapper-41 {
  color: #8f8f9f;
  font-family: "Switzer-Semibold", Helvetica;
  font-size: 32px;
  font-weight: 400;
  left: 0;
  letter-spacing: -0.62px;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 309px;
}

.iphone .text-wrapper-42 {
  color: #ffffff;
  font-family: "Switzer-Semibold", Helvetica;
  font-size: 32px;
  font-weight: 400;
  height: 294px;
  left: 0;
  letter-spacing: -0.62px;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 309px;
}

.iphone .background-2 {
  align-items: flex-start;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgb(11.28, 10.28, 59.04) 0%,
    rgba(11, 10, 59, 0.21) 100%
  );
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 20px;
  padding: 20px 0px;
  position: absolute;
  top: 4700px;
  width: 350px;
}

.iphone .container-wrapper {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.iphone .container-15 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  padding: 0px 0px 0px 20px;
  position: relative;
  width: 100%;
}

.iphone .mask-group-5 {
  height: 32px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 97px;
}

.iphone .container-16 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 100%;
}

.iphone .text-wrapper-43 {
  color: #f1f1ef;
  font-family: "Switzer-Semibold", Helvetica;
  font-size: 36px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  width: 290px;
}

.iphone .text-wrapper-44 {
  align-self: stretch;
  color: #adadad;
  font-family: "Switzer-Regular", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: -0.18px;
  line-height: normal;
  position: relative;
}

.iphone .group-wrapper {
  align-self: stretch;
  flex: 0 0 auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.iphone .group-3 {
  height: 235px;
  left: 3px;
  position: relative;
  top: 41px;
  width: 553px;
}

.iphone .overlap-10 {
  height: 234px;
  left: 0;
  position: absolute;
  top: 0;
  width: 553px;
}

.iphone .group-4 {
  height: 234px;
  left: 1px;
  position: absolute;
  top: 0;
  width: 552px;
}

.iphone .overlap-group-7 {
  background-image: url(/static/img/mobile/group-15.png);
  background-size: 100% 100%;
  height: 222px;
  position: relative;
  width: 327px;
}

.iphone .text-wrapper-45 {
  color: #ffffff;
  font-family: "Switzer-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  left: 40px;
  letter-spacing: 0;
  line-height: 17.6px;
  position: absolute;
  top: 33px;
  width: 245px;
}

.iphone .made-simple {
  height: 15px;
  left: 41px;
  position: absolute;
  top: 50px;
  width: 96px;
}

.iphone .group-5 {
  height: 221px;
  left: 3px;
  position: absolute;
  top: 1px;
  width: 325px;
}

.iphone .group-6 {
  height: 3px;
  left: 42px;
  position: absolute;
  top: 76px;
  width: 10px;
}

.iphone .group-7 {
  height: 3px;
  left: 42px;
  position: absolute;
  top: 107px;
  width: 11px;
}

.iphone .group-8 {
  height: 3px;
  left: 42px;
  position: absolute;
  top: 137px;
  width: 11px;
}

.iphone .group-9 {
  height: 3px;
  left: 42px;
  position: absolute;
  top: 168px;
  width: 11px;
}

.iphone .group-10 {
  height: 1px;
  left: 66px;
  position: absolute;
  top: 78px;
  width: 262px;
}

.iphone .group-11 {
  height: 1px;
  left: 66px;
  position: absolute;
  top: 108px;
  width: 262px;
}

.iphone .group-12 {
  height: 1px;
  left: 66px;
  position: absolute;
  top: 138px;
  width: 262px;
}

.iphone .group-13 {
  height: 1px;
  left: 66px;
  position: absolute;
  top: 170px;
  width: 262px;
}

.iphone .group-14 {
  height: 108px;
  left: 66px;
  position: absolute;
  top: 67px;
  width: 262px;
}

.iphone .group-15 {
  height: 91px;
  left: 70px;
  position: absolute;
  top: 84px;
  width: 258px;
}

.iphone .group-16 {
  height: 25px;
  left: 0;
  position: absolute;
  top: 11px;
  width: 2px;
}

.iphone .group-17 {
  height: 81px;
  left: 2px;
  position: absolute;
  top: 140px;
  width: 326px;
}

.iphone .clip-path-group {
  height: 10px;
  left: 191px;
  position: absolute;
  top: 43px;
  width: 12px;
}

.iphone .group-18 {
  height: 10px;
  left: 39px;
  position: absolute;
  top: 12px;
  width: 46px;
}

.iphone .group-19 {
  height: 10px;
  left: 299px;
  position: absolute;
  top: 87px;
  width: 45px;
}

.iphone .group-20 {
  height: 66px;
  left: 321px;
  position: absolute;
  top: 108px;
  width: 2px;
}

.iphone .group-21 {
  height: 18px;
  left: 304px;
  position: absolute;
  top: 99px;
  width: 36px;
}

.iphone .text-wrapper-46 {
  color: #ffffff;
  font-family: "Switzer-Regular", Helvetica;
  font-size: 4.7px;
  font-weight: 400;
  left: 55px;
  letter-spacing: 0;
  line-height: 5.1px;
  opacity: 0.5;
  position: absolute;
  top: 15px;
  width: 24px;
}

.iphone .overlap-11 {
  height: 15px;
  left: -1312px;
  position: absolute;
  top: -4183px;
  width: 49px;
}

.iphone .group-22 {
  height: 15px;
  left: 0;
  position: absolute;
  top: 0;
  width: 47px;
}

.iphone .group-23 {
  height: 14px;
  left: 1px;
  position: absolute;
  top: 0;
  width: 47px;
}

.iphone .container-17 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 36px;
  left: 0;
  padding: 0px 20px;
  position: absolute;
  top: 5520px;
  width: 385px;
}

.iphone .icon {
  background: linear-gradient(
    180deg,
    rgb(108, 83, 183) 0%,
    rgb(18, 19, 64) 78%,
    rgb(6.51, 7.1, 33.62) 100%
  );
  border-radius: 14.25px;
  height: 62.64px;
  position: relative;
  width: 62.64px;
}

.iphone .union {
  height: 31px;
  left: 20px;
  position: absolute;
  top: 16px;
  width: 23px;
}

.iphone .upgrade-to-the-new {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  align-self: stretch;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(255, 255, 255, 0.6) 0%,
    rgb(255, 255, 255) 100%
  );
  background-clip: text;
  color: transparent;
  font-family: "Switzer-Medium", Helvetica;
  font-size: 32px;
  font-weight: 500;
  letter-spacing: -0.80px;
  line-height: normal;
  position: relative;
  text-align: center;
}

.iphone .follow-2 {
  display: flex;
  align-items: center; /* Ensures vertical alignment */
  background: linear-gradient(
    180deg,
    rgba(243, 238, 255, 0) 0%,
    rgba(243, 238, 255, 0.04) 100%
  );
  border: 1px solid #f3eeff14;
  border-radius: 8px;
  box-shadow: inset 0px 0px 12px #ffffff14;
  height: 38px;
  padding: 8px 15px 8px 10px; /* Adjust padding for spacing */
  position: relative;
  left: 33%; /* Adjust as needed */
  top: 5830px; /* Adjust as needed */
  width: 127px;
  gap: 10px;
}

.iphone .image {
  height: 22px;
  width: 21px;
  object-fit: contain;
  display: inline-block; /* Ensures inline positioning */
  margin-left: -1px;
}

.iphone .text-wrapper-47 {
  color: #ffffff;
  font-family: "Switzer-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  width: auto;
  margin-right: -5px;
}

.iphone .text-wrapper-47 a {
  color: #efedfd; /* Color of the email link */
  text-decoration: none; /* Remove default underline */
}

.iphone .text-wrapper-47 a:hover {
  text-decoration: underline; /* Add underline on hover */
}

.iphone .frame-84 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 19px;
  position: absolute;
  top: 6039px;
  width: 265px;
}

.iphone .div-wrapper-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 0px 16px 0px 0px;
  position: relative;
}

.iphone .text-wrapper-48 {
  color: #efedfd99;
  font-family: "Switzer-Regular", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.iphone a {
  color: #ffffff;
  text-decoration: none;
}

.iphone a:visited {
  color: inherit; /* Ensure links retain their default color after being clicked */
}

.iphone a:hover {
  text-decoration: underline;
}

.iphone a:active {
  color: inherit;
}

.iphone .text-wrapper-49 {
  color: #efedfd;
  font-family: "Switzer-Regular", Helvetica;
  font-size: 21.9px;
  font-weight: 400;
  height: 11px;
  left: 19px;
  letter-spacing: 0;
  line-height: 11.0px;
  position: absolute;
  top: 5997px;
  width: 197px;
}

.iphone .text-wrapper-49 a {
  color: #efedfd; /* Color of the email link */
  text-decoration: none; /* Remove default underline */
}

.iphone .text-wrapper-49 a:hover {
  text-decoration: underline; /* Add underline on hover */
}

.iphone .logo {
  height: 27px;
  left: 19px;
  position: absolute;
  top: 5938px;
  width: 129px;
}

.iphone .follow-99 {
  bottom: 20px; /* Stick to the bottom of the viewport */
  right: 10px; /* Keep a few pixels from the right */
  width: auto; /* Maintain dynamic width */
  height: 42px; /* Slightly larger height for touch usability */
  padding: 10px; /* Increase padding for mobile */
  align-items: center;
  background: linear-gradient(
        180deg,
        rgba(243, 238, 255, 0) 0%,
        rgba(243, 238, 255, 0.04) 100%
  );
  border-radius: 8px;
  box-shadow: inset 0px 0px 12px #ffffff14;
  display: inline-flex;
  gap: 10px;
  position: fixed;
  top: 32px;
  overflow: hidden;
  z-index: 1000; /* Ensure it stays on top */
  max-width: calc(100% - 40px); /* Prevent it from going outside the viewport */
}

.iphone .follow-99 a {
  display: inline-flex; /* Make it behave like a container */
  align-items: center; /* Align items vertically */
  gap: 10px; /* Ensure consistent spacing */
  text-decoration: none; /* Remove underline from the link */
  color: inherit; /* Keep the text color as it was */
}

.iphone .apple-icon-99 {
  height: 16px;
  width: 16px;
  position: relative;
}

.iphone .text-wrapper-99 {
  color: #ffffff;
  font-family: "Switzer-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-right: 7px; /* Adjust as needed */
}

.iphone .language-toggle-mobile {
  position: absolute;
  top: 32px; /* Lower the button slightly (adjust as needed) */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, 0); /* Horizontal centering only */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  padding: 8px 10px;
  background: linear-gradient(
        180deg,
        rgba(243, 238, 255, 0) 0%,
        rgba(243, 238, 255, 0.04) 100%
  );
  border: 1px solid #f3eeff14; /* Match style */
  border-radius: 8px;
  box-shadow: inset 0px 0px 12px #ffffff14;
  z-index: 1000; /* Ensure it stays above other elements */
}

.iphone .language-toggle-mobile button {
  background: transparent;
  border: none;
  color: #ffffff;
  font-family: "Switzer-Medium", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
}

html, body {
  background-color: #000212;
  margin: 0;
  padding: 0;
  height: 100%;
}

}