
/* Desktop-specific styles */
@media (min-width: 769) {
.LP {
  background-color: #000212;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.LP .div {
  background-color: #000212;
  height: 6938px;
  position: relative;
  width: 100%; /* Full width */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  padding-bottom: 2rem; /* Ensure some space after footer */
  z-index: 0; /* Default layer */
}

.LP .overlap {
  height: 1552px;
  left: 0;
  position: absolute;
  top: 5147px;
  width: 1440px;
}

.LP .gradient {
  height: 1552px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1440px;
}

.LP .background {
  align-items: center;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgb(11.28, 10.28, 59.04) 0%,
    rgba(11, 10, 59, 0.21) 100%
  );
  border-radius: 20px;
  display: flex;
  height: 563px;
  left: 119px;
  position: absolute;
  top: 54px;
  width: 1202px;
}

.LP .container {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
}

.LP .container-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  padding: 0px 0px 0px 108.17px;
  position: relative;
  width: 100%;
}


.LP .overlay-shadow {
  display: flex;
  align-items: center;
  justify-content: center; /* Center the content inside */
  backdrop-filter: blur(6px) brightness(100%);
  background-color: rgba(255, 255, 255, 0.01); /* Ensure transparency remains */
  border-radius: 32px;
  box-shadow: inset 0px -7px 11px rgba(164, 143, 255, 0.12);
  padding: 6px 14px;
  position: relative;
  width: fit-content; /* Shrinks to fit content */
}

.LP .div-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers the text */
  justify-content: center; /* Vertically center the text */
  position: relative;
}

.LP .text-wrapper {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center; /* Ensures text is centered */
  white-space: nowrap; /* Prevents text wrapping */
}

.LP .mask-group {
  height: 32px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 93px;
}

.LP .container-3 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 100%;
}

.LP .p {
  color: #f1f1ef;
  font-family: "Switzer-Semibold", Helvetica;
  font-size: 40px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 48px;
  margin-right: -0.17px;
  margin-top: -1.00px;
  position: relative;
  width: 493px;
}

.LP .text-wrapper-2 {
  align-self: stretch;
  color: #adadad;
  font-family: "Switzer-Regular", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: -0.18px;
  line-height: 27px;
  position: relative;
}

.LP .group-wrapper {
  flex: 1;
  flex-grow: 1;
  margin-bottom: -0.23px;
  margin-top: -0.23px;
  overflow: hidden;
  position: relative;
}

.LP .group {
  height: 503px;
  left: 76px;
  position: relative;
  top: 89px;
  width: 592px;
}

.LP .overlap-group {
  height: 501px;
  position: relative;
}

.LP .overlap-group-wrapper {
  height: 501px;
  left: 1px;
  position: absolute;
  top: 0;
  width: 591px;
}

.LP .overlap-group-2 {
  background-image: url(/static/img/desktop/group-15.png);
  background-size: 100% 100%;
  height: 475px;
  position: relative;
  width: 525px;
}

.LP .text-wrapper-3 {
  color: #ffffff;
  font-family: "Switzer-Medium", Helvetica;
  font-size: 34.3px;
  font-weight: 500;
  left: 43px;
  letter-spacing: 0;
  line-height: 37.7px;
  position: absolute;
  top: 72px;
  white-space: nowrap;
}

.LP .made-simple {
  height: 32px;
  left: 46px;
  position: absolute;
  top: 107px;
  width: 206px;
}

.LP .img {
  height: 473px;
  left: 3px;
  position: absolute;
  top: 2px;
  width: 523px;
}

.LP .group-2 {
  height: 7px;
  left: 45px;
  position: absolute;
  top: 164px;
  width: 11px;
}

.LP .group-3 {
  height: 7px;
  left: 45px;
  position: absolute;
  top: 228px;
  width: 12px;
}

.LP .group-4 {
  height: 7px;
  left: 45px;
  position: absolute;
  top: 293px;
  width: 12px;
}

.LP .group-5 {
  height: 7px;
  left: 45px;
  position: absolute;
  top: 360px;
  width: 12px;
}

.LP .group-6 {
  height: 1px;
  left: 71px;
  position: absolute;
  top: 167px;
  width: 446px;
}

.LP .group-7 {
  height: 1px;
  left: 71px;
  position: absolute;
  top: 231px;
  width: 446px;
}

.LP .group-8 {
  height: 1px;
  left: 71px;
  position: absolute;
  top: 296px;
  width: 446px;
}

.LP .group-9 {
  height: 1px;
  left: 71px;
  position: absolute;
  top: 364px;
  width: 446px;
}

.LP .group-10 {
  height: 230px;
  left: 70px;
  position: absolute;
  top: 145px;
  width: 455px;
}

.LP .group-11 {
  height: 35px;
  left: 464px;
  position: absolute;
  top: 187px;
  width: 52px;
}

.LP .group-12 {
  height: 32px;
  left: 465px;
  position: absolute;
  top: 187px;
  width: 52px;
}

.LP .group-13 {
  height: 196px;
  left: 74px;
  position: absolute;
  top: 180px;
  width: 451px;
}

.LP .group-14 {
  height: 54px;
  left: 0;
  position: absolute;
  top: 23px;
  width: 2px;
}

.LP .group-15 {
  height: 174px;
  left: 2px;
  position: absolute;
  top: 300px;
  width: 524px;
}

.LP .clip-path-group {
  height: 21px;
  left: 204px;
  position: absolute;
  top: 93px;
  width: 13px;
}

.LP .group-16 {
  height: 22px;
  left: 41px;
  position: absolute;
  top: 26px;
  width: 49px;
}

.LP .group-17 {
  height: 22px;
  left: 320px;
  position: absolute;
  top: 187px;
  width: 49px;
}

.LP .group-18 {
  height: 141px;
  left: 344px;
  position: absolute;
  top: 231px;
  width: 2px;
}

.LP .group-19 {
  height: 38px;
  left: 325px;
  position: absolute;
  top: 213px;
  width: 39px;
}

.LP .text-wrapper-4 {
  color: #ffffff;
  font-family: "Switzer-Regular", Helvetica;
  font-size: 10px;
  font-weight: 400;
  left: 59px;
  letter-spacing: 0;
  line-height: 11.0px;
  opacity: 0.5;
  position: absolute;
  top: 32px;
  white-space: nowrap;
}

.LP .container-4 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 36px;
  left: 208px;
  position: absolute;
  top: 861px;
  width: 1024px;
}

.LP .icon {
  background: linear-gradient(
    180deg,
    rgb(108, 83, 183) 0%,
    rgb(18, 19, 64) 78%,
    rgb(6.51, 7.1, 33.62) 100%
  );
  border-radius: 19px;
  height: 83.52px;
  position: relative;
  width: 83.52px;
}

.LP .union {
  height: 42px;
  left: 26px;
  position: absolute;
  top: 21px;
  width: 30px;
}

.LP .heading-wrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  position: relative;
  width: 100%;
}

.LP .heading {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  margin-left: -72.43px;
  margin-right: -72.43px;
  padding: 0px 1.14e-13px 10.4px 0px;
  position: relative;
}

.LP .upgrade-to-the-new {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(255, 255, 255, 0.6) 0%,
    rgb(255, 255, 255) 100%
  );
  background-clip: text;
  color: transparent;
  font-family: "Switzer-Medium", Helvetica;
  font-size: 72px;
  font-weight: 500;
  height: 152px;
  letter-spacing: -0.80px;
  line-height: 72px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  width: fit-content;
  max-width: 1050px;
}

.LP .follow {
  align-items: center;
  background-color: #ffffff;
  border-radius: 8px;
  display: inline-flex;
  gap: 10px;
  height: 38px;
  padding: 8px 15px;
  position: relative;
  margin-bottom: 20px; /* Add spacing below this element */
  z-index: 1; /* Ensure it appears above any other overlapping elements */
}

.LP .follow a {
  display: inline-flex; /* Make it behave like a container */
  align-items: center; /* Align items vertically */
  gap: 10px; /* Ensure consistent spacing */
  text-decoration: none; /* Remove underline from the link */
  color: inherit; /* Keep the text color as it was */
}

.LP .border {
  border: 1px solid;
  border-color: #f3eeff14;
  border-radius: 8px;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.LP .SVG {
  height: 16px;
  position: relative;
  width: 16px;
}

.LP .text-wrapper-5 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(
    180deg,
    rgb(18, 19, 64) 0%,
    rgb(108, 83, 183) 100%
  );
  background-clip: text;
  color: transparent;
  font-family: "Switzer-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.LP .follow-2 {
  align-items: center;
  background: linear-gradient(
    180deg,
    rgba(243, 238, 255, 0) 0%,
    rgba(243, 238, 255, 0.04) 100%
  );
  border-radius: 8px;
  box-shadow: inset 0px 0px 12px #ffffff14;
  display: inline-flex;
  gap: 10px;
  height: auto;
  left: 1001px;
  padding: 8px 12px;
  position: relative;
  top: 1403px;
  width: fit-content;
}

.LP .content-wrapper {
  display: flex;
  align-items: center; /* Align logo and text horizontally */
  gap: 8px; /* Spacing between logo and text */
}

.LP .border-2 {
  border: 1px solid;
  border-color: #f3eeff14;
  border-radius: 8px;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.LP .image {
  height: 22px;
  width: 22px;
  flex-shrink: 0; /* Prevent the logo from resizing */
}

.LP .text-container {
  flex-wrap: nowrap; /* Prevent text wrapping */
  font-family: "Inter", sans-serif;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap; /* Ensure text stays on one line */
}

.LP .white-text {
  color: #ffffff;
  font-weight: 500;
}

.LP .light-purple-text {
  color: #d3c4f3;
  font-weight: 400;
}

.LP .follow-3 {
  align-items: center;
  background: linear-gradient(
    180deg,
    rgba(243, 238, 255, 0) 0%,
    rgba(243, 238, 255, 0.04) 100%
  );
  border-radius: 8px;
  box-shadow: inset 0px 0px 12px #ffffff14;
  display: inline-flex;
  gap: 10px;
  height: 38px;
  left: 1162px;
  padding: 8px 15px;
  position: absolute;
  top: 1451px;
}

.LP .follow-3 a {
  display: inline-flex; /* Make it behave like a container */
  align-items: center; /* Align items vertically */
  gap: 10px; /* Ensure consistent spacing */
  text-decoration: none; /* Remove underline from the link */
  color: inherit; /* Keep the text color as it was */
}

.LP .text-wrapper-6 {
  color: #ffffff;
  font-family: "Switzer-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.LP .text-wrapper-7 {
  color: #efedfd;
  font-family: "Switzer-Regular", Helvetica;
  font-size: 40px;
  font-weight: 400;
  height: 20px;
  left: 156px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 1485px;
  width: 359px;
}
/* Style specifically for the email link */
.LP .text-wrapper-7 a {
  color: #efedfd; /* Color of the email link */
  text-decoration: none; /* Remove default underline */
}

.LP .text-wrapper-7 a:hover {
  text-decoration: underline; /* Add underline on hover */
}

.LP .logo {
  height: 32px;
  left: 156px;
  position: absolute;
  top: 1405px;
  width: 153px;
}

.LP .overlap-2 {
  height: 1160px;
  left: 0;
  position: absolute;
  top: 2843px;
  width: 1447px;
}

.LP .group-20 {
  height: 524px;
  left: 233px;
  position: absolute;
  top: 236px;
  width: 989px;
}

.LP .rectangle {
  background: linear-gradient(
    180deg,
    rgb(0, 2, 18) 0%,
    rgba(0, 2, 18, 0.78) 45%,
    rgba(0, 2, 18, 0) 100%
  );
  height: 731px;
  left: 28px;
  position: absolute;
  top: 39px;
  transform: rotate(180.00deg);
  width: 1419px;
}

.LP .blur {
  height: 932px;
  left: 0;
  position: absolute;
  top: 24px;
  width: 1440px;
}

.LP .follow-4 {
  align-items: center;
  background-color: #ffffff;
  border-radius: 8px;
  display: inline-flex;
  gap: 10px;
  height: 38px;
  left: 659px;
  padding: 8px 15px;
  position: absolute;
  top: 745px;
}

.LP .follow-4 a {
  display: inline-flex; /* Make it behave like a container */
  align-items: center; /* Align items vertically */
  gap: 10px; /* Ensure consistent spacing */
  text-decoration: none; /* Remove underline from the link */
  color: inherit; /* Keep the text color as it was */
}

.LP .iphone-pro {
  height: 680px;
  left: 555px;
  position: absolute;
  top: 0;
  width: 331px;
}

.LP .overlap-3 {
  height: 673px;
  position: relative;
}

.LP .overlap-4 {
  height: 852px;
  position: relative;
  width: 393px;
}

.LP .frame {
  border-radius: 11.45px;
  height: 534px;
  left: 10px;
  overflow: hidden;
  position: absolute;
  top: 37px;
  width: 280px;
}

.LP .overlap-5 {
  height: 794px;
  position: relative;
  width: 368px;
}

.LP .frame-wrapper {
  align-items: flex-end;
  display: flex;
  gap: 187.01px;
  justify-content: space-around;
  left: 14px;
  position: absolute;
  top: 16px;
  width: 247px;
}

.LP .silver {
  height: 680px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 331px;
}

.LP .container-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 38px;
  left: 0;
  position: absolute;
  top: 832px;
  width: 1440px;
}

.LP .container-5 {
  height: 328px;
  position: relative;
  width: 900px;
}

.LP .overlap-6 {
  height: 164px;
  left: -24px;
  position: absolute;
  top: 164px;
  width: 948px;
}

.LP .horizontal-divider {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.08) 24.33%,
    rgba(255, 255, 255, 0.08) 49.55%,
    rgba(255, 255, 255, 0.08) 74.78%,
    rgba(255, 255, 255, 0) 100%
  );
  height: 1px;
  left: 0;
  position: absolute;
  top: 0;
  width: 948px;
}

.LP .overlap-wrapper {
  height: 164px;
  left: 174px;
  position: absolute;
  top: 0;
  width: 300px;
}

.LP .overlap-7 {
  height: 164px;
  position: relative;
}

.LP .div-2 {
  height: 164px;
  left: 0;
  position: absolute;
  top: 0;
  width: 300px;
}

.LP .container-6 {
  align-items: center;
  display: flex;
  flex-direction: column;
  left: 0;
  padding: 0px 67.6px 0px 67.59px;
  position: absolute;
  top: 84px;
  width: 300px;
}

.LP .text-wrapper-28 {
  color: #f4f0ff;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.LP .container-7 {
  align-items: center;
  display: flex;
  flex-direction: column;
  left: 0;
  padding: 0px 56.5px 0px 56.48px;
  position: absolute;
  top: 108px;
  width: 300px;
}

.LP .text-wrapper-29 {
  color: #efedfd99;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.LP .vertical-divider {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.12) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  height: 164px;
  left: 299px;
  position: absolute;
  top: 0;
  width: 1px;
}

.LP .frame-26 {
  height: 30px;
  left: 135px;
  position: absolute;
  top: 30px;
  width: 30px;
}

.LP .container-8 {
  height: 164px;
  left: 474px;
  position: absolute;
  top: 0;
  width: 300px;
}

.LP .container-9 {
  align-items: center;
  display: flex;
  flex-direction: column;
  left: 0;
  padding: 0px 94.59px 0px 94.58px;
  position: absolute;
  top: 84px;
  width: 300px;
}

.LP .text-wrapper-30 {
  color: #f4f0ff;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  margin-left: -18.08px;
  margin-right: -18.08px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.LP .container-10 {
  align-items: center;
  display: flex;
  flex-direction: column;
  left: 0;
  padding: 0px 88.52px 0px 88.5px;
  position: absolute;
  top: 108px;
  width: 300px;
}

.LP .text-wrapper-31 {
  color: #efedfd99;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  margin-left: -44.51px;
  margin-right: -44.51px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.LP .frame-27 {
  height: 30px;
  left: 135px;
  position: absolute;
  top: 32px;
  width: 30px;
}

.LP .receipt-capture-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  left: 0;
  padding: 0px 64.22px 0px 64.2px;
  position: absolute;
  top: 84px;
  width: 300px;
}

.LP .receipt-capture {
  color: #f4f0ff;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  margin-left: -12.71px;
  margin-right: -12.71px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.LP .container-11 {
  align-items: center;
  display: flex;
  flex-direction: column;
  left: 0;
  padding: 0px 48.33px 0px 48.31px;
  position: absolute;
  top: 108px;
  width: 300px;
}

.LP .text-wrapper-32 {
  color: #efedfd99;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  margin-left: -0.32px;
  margin-right: -0.32px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  width: 204px;
}

.LP .frame-28 {
  height: 30px;
  left: 135px;
  position: absolute;
  top: 35px;
  width: 30px;
}

.LP .container-12 {
  height: 164px;
  left: 300px;
  position: absolute;
  top: 0;
  width: 300px;
}

.LP .frame-29 {
  height: 30px;
  left: 135px;
  position: absolute;
  top: 28px;
  width: 30px;
}

.LP .organize-track-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  left: 0;
  padding: 0px 57.5px;
  position: absolute;
  top: 84px;
  width: 300px;
}

.LP .container-13 {
  align-items: center;
  display: flex;
  flex-direction: column;
  left: 0;
  padding: 0px 39.2px 0px 39.19px;
  position: absolute;
  top: 108px;
  width: 300px;
}

.LP .container-14 {
  height: 164px;
  left: 600px;
  position: absolute;
  top: 0;
  width: 300px;
}

.LP .container-15 {
  align-items: center;
  display: flex;
  flex-direction: column;
  left: 0;
  padding: 0px 36.83px 0px 36.81px;
  position: absolute;
  top: 84px;
  width: 300px;
}

.LP .container-16 {
  align-items: center;
  display: flex;
  flex-direction: column;
  left: 0;
  padding: 0px 34.54px 0px 34.55px;
  position: absolute;
  top: 108px;
  width: 300px;
}

.LP .frame-30 {
  height: 30px;
  left: 135px;
  position: absolute;
  top: 29px;
  width: 30px;
}

.LP .overlap-8 {
  height: 1173px;
  position: absolute;
  top: 0;
  width: 100%; /* Make it stretch to the full width of the screen */
  max-width: none; /* Ensure no width restrictions */
  left: 0; /* Align to the left edge */
  object-fit: cover; /* Ensure content fills the container */
}

.LP .frame-31 {
  height: 506px;
  position: absolute;
  top: 0;
  width: 100%; /* Stretch to full width */
  max-width: none; /* Ensure no width restrictions */
  left: 0; /* Align to the left edge */
  object-fit: cover; /* Maintain aspect ratio and cover */
  z-index: 0; /* Push them behind other elements */
  pointer-events: none; /* Prevent them from interfering with interactions */
}

.LP .blur-2 {
  height: 737px;
  position: absolute;
  top: 0;
  width: 100%; /* Stretch to full width */
  max-width: none; /* Remove fixed width */
  left: 0; /* Align to the left edge */
  object-fit: cover; /* Ensure it covers the container */
  mix-blend-mode: screen; /* Maintain blend mode */
  z-index: 0; /* Push them behind other elements */
  pointer-events: none; /* Prevent them from interfering with interactions */
}

.LP .margin {
  align-items: center;
  display: flex;
  flex-direction: column;
  left: 32px;
  padding: 0px 144px;
  position: absolute;
  top: 48px;
  width: 1376px;
}

.LP .container-17 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  max-width: 1088px;
  padding: 64px 32px 0px;
  position: relative;
  width: 1088px;
}

.LP .container-18 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.LP .heading-margin {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 24px 0px;
  position: relative;
}

.LP .save-time-and-money sup {
  font-size: 0.65em; /* Adjust size as needed */
  vertical-align: top; /* Align with the text */
  line-height: 1; /* Prevent extra spacing issues */
}

.LP .save-time-and-money {
  color: #d9d9d9;
  font-family: "Switzer-Medium", Helvetica;
  font-size: 80px;
  font-weight: 500;
  letter-spacing: -1.60px;
  line-height: 80px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.LP .margin-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 0px 0px 48px;
  position: relative;
}

.LP .container-19 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 0px 220.67px;
  position: relative;
}

.LP .text-wrapper-33 {
  color: #b4bcd0;
  font-family: "Switzer-Regular", Helvetica;
  font-size: 21px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 28.6px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  width: 546px;
}

.LP .iphone-pro-mockup {
  height: auto;
  left: auto;
  position: absolute;
  top: 478px;
  width: 100%;
}

.LP .container-20 {
  align-items: center;
  display: flex;
  gap: 24px;
  left: 176px;
  max-width: 1088px;
  padding: 0px 32px;
  position: absolute;
  top: 1898px;
  width: 1088px;
}

.LP .frame-32 {
  height: 455.34px;
  overflow: hidden;
  position: relative;
  width: 500px;
}

.LP .overlap-9 {
  height: 1021px;
  position: relative;
}

.LP .background-2 {
  align-items: flex-start;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgb(102, 99, 246) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  border-radius: 48px;
  display: flex;
  flex-direction: column;
  left: 0;
  overflow: hidden;
  padding: 32px 48px 311.34px;
  position: absolute;
  top: 0;
  width: 500px;
}

.LP .heading-margin-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 0px 0px 8px;
  position: relative;
  width: 100%;
}

.LP .div-3 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.LP .text-wrapper-34 {
  align-self: stretch;
  color: #f7f8f8;
  font-family: "Switzer-Medium", Helvetica;
  font-size: 27px;
  font-weight: 500;
  letter-spacing: -0.24px;
  line-height: 31.2px;
  margin-top: -1.00px;
  position: relative;
}

.LP .container-21 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  margin-top: -1.31e-12px;
  max-width: 345px;
  position: relative;
  width: fit-content;
}

.LP .text-wrapper-35 {
  align-self: stretch;
  color: #b4bcd0;
  font-family: "Switzer-Medium", Helvetica;
  font-size: 15.8px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1.00px;
  position: relative;
}

.LP .mask-group-2 {
  height: 455px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 500px;
}

.LP .archive-3 {
  -webkit-backdrop-filter: blur(30px) brightness(100%);
  backdrop-filter: blur(30px) brightness(100%);
  background: linear-gradient(
    180deg,
    rgba(18, 19, 64, 0.5) 0%,
    rgba(46, 49, 166, 0) 100%
  );
  border-color: transparent;
  border-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.2) 50.5%,
      rgba(255, 255, 255, 0) 100%
    )
    1;
  border-radius: 53px;
  height: 848px;
  left: 48px;
  position: absolute;
  top: 173px;
  width: 404px;
  display: flex; /* Flexbox for alignment */
  justify-content: center; /* Center horizontally */
  align-items: flex-start; /* Align image to the top */
  overflow: hidden; /* Clip overflowing parts of the image */
}

.LP .frame-33 {
  height: auto; /* Maintain aspect ratio */
  width: 100%; /* Fit the width of the container */
  max-height: 100%; /* Ensure it doesn't exceed the container's height */
  object-fit: contain; /* Prevent stretching and keep aspect ratio */
}

.LP .frame-34 {
  height: 50px;
  left: 0;
  position: absolute;
  top: 238px;
  width: 377px;
}

.LP .frame-35 {
  height: 37px;
  left: 15px;
  position: absolute;
  top: 5px;
  width: 41px;
}

.LP .transactions-2 {
  height: 37px;
  left: -6px;
  position: relative;
  width: 57px;
}

.LP .frame-36 {
  height: 17px;
  left: -325px;
  position: absolute;
  top: -1833px;
  width: 17px;
}

.LP .frame-37 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 6.17px;
  position: relative;
  width: 46px;
}

.LP .frame-38 {
  height: 17.48px;
  margin-left: -401.38px;
  margin-top: -1833.48px;
  position: relative;
  width: 17.48px;
}

.LP .frame-39 {
  height: 46px;
  left: -162px;
  position: absolute;
  top: -1829px;
  width: 46px;
}

.LP .frame-40 {
  height: 17.48px;
  margin-left: -554.55px;
  margin-top: -1833.48px;
  position: relative;
  width: 17.48px;
}

.LP .frame-41 {
  height: 16.45px;
  margin-left: -628.82px;
  margin-top: -1833.48px;
  position: relative;
  width: 16.45px;
}

.LP .frame-42 {
  align-items: center;
  border: 1.03px solid;
  border-color: #ffffff1a;
  border-radius: 205.6px;
  display: flex;
  justify-content: space-between;
  left: 21px;
  padding: 10.28px 20.56px;
  position: absolute;
  top: 80px;
  width: 114px;
}

.LP .frame-43 {
  align-items: center;
  border: 1.03px solid;
  border-color: #ffffff1a;
  border-radius: 205.6px;
  display: flex;
  justify-content: space-between;
  left: 145px;
  padding: 10.28px 20.56px;
  position: absolute;
  top: 80px;
  width: 113px;
}

.LP .frame-44 {
  align-items: center;
  border: 1.03px solid;
  border-color: #ffffff1a;
  border-radius: 205.6px;
  display: flex;
  justify-content: space-between;
  left: 268px;
  padding: 10.28px 20.56px;
  position: absolute;
  top: 80px;
  width: 114px;
}

.LP .frame-45 {
  height: 25px;
  left: 37px;
  position: absolute;
  top: 32px;
  width: 57px;
}

.LP .frame-46 {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 2px 2.06px;
  left: 22px;
  position: absolute;
  top: 81px;
  width: 361px;
}

.LP .frame-47 {
  -webkit-backdrop-filter: blur(4px) brightness(100%);
  align-items: flex-start;
  backdrop-filter: blur(4px) brightness(100%);
  background: linear-gradient(
    180deg,
    rgba(18, 19, 64, 0.5) 0%,
    rgba(108, 83, 183, 0.5) 100%
  );
  border-radius: 20px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  height: 173px;
  justify-content: space-between;
  padding: 20px;
  position: relative;
}

.LP .frame-48 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 5px;
  position: relative;
}

.LP .frame-49 {
  height: auto; /* Maintain aspect ratio */
  width: 100%; /* Fit the width of the container */
  max-height: 100%; /* Ensure it doesn't exceed the container's height */
  object-fit: contain; /* Prevent stretching and keep aspect ratio */
}

.LP .container-22 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 23.69px;
  left: 176px;
  max-width: 1088px;
  padding: 0px 32px;
  position: absolute;
  top: 1483px;
  width: 1088px;
}

.LP .mask-group-3 {
  height: 32px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 97px;
}

.LP .heading-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 0px 287.37px 0px 287.36px;
  position: relative;
  width: 100%;
}

.LP .text-wrapper-46 {
  align-self: stretch;
  color: #f7f8f8;
  font-family: "Switzer-Medium", Helvetica;
  font-size: 51.4px;
  font-weight: 500;
  letter-spacing: -1.12px;
  line-height: 56px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
}

.LP .in-today-s-fast {
  color: #b4bcd0;
  font-family: "Switzer-Regular", Helvetica;
  font-size: 21.1px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 28.6px;
  position: relative;
  text-align: center;
  width: 470px;
}

.LP .container-23 {
  height: 168px;
  left: 0;
  position: absolute;
  top: 2563px;
  width: 1440px;
}

.LP .heading-3 {
  align-items: center;
  display: flex;
  flex-direction: column;
  left: 0;
  padding: 0px 390.12px 0px 390.13px;
  position: absolute;
  top: 44px;
  width: 1440px;
}

.LP .text-wrapper-47 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(
    180deg,
    rgb(255, 255, 255) 0%,
    rgba(255, 255, 255, 0.7) 100%
  );
  background-clip: text;
  color: transparent;
  font-family: "Switzer-Regular", Helvetica;
  font-size: 56px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 64px;
  margin-left: -3.12px;
  margin-right: -3.12px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.LP .container-24 {
  align-items: center;
  display: flex;
  flex-direction: column;
  left: 348px;
  max-width: 744px;
  padding: 0px 108.02px 0px 108px;
  position: absolute;
  top: 120px;
  width: 744px;
}

.LP .text-wrapper-48 {
  color: #efedfdb2;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  margin-left: -0.01px;
  margin-right: -0.01px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  width: 528px;
}

.LP .overlay-shadow-2 {
  -webkit-backdrop-filter: blur(6px) brightness(100%);
  align-items: center;
  backdrop-filter: blur(6px) brightness(100%);
  background-color: #ffffff01;
  border-radius: 32px;
  box-shadow: inset 0px -7px 11px #a48fff1f;
  display: inline-flex;
  left: 676px;
  overflow: hidden;
  padding: 6px 14px 6px 15px;
  position: absolute;
  top: 0;
}

.LP .mask-group-4 {
  height: 32px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 88px;
}

.LP .section {
  align-items: center;
  display: flex;
  flex-direction: column;
  left: 0;
  padding: 0px 52.26px 0px 52.27px;
  position: absolute;
  top: 4161px;
  width: 1440px;
}

.LP .container-25 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  max-width: 1266px;
  padding: 0px 32px;
  position: relative;
  width: 1266px;
}

.LP .container-26 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
}

.LP .container-27 {
  align-items: center;
  align-self: stretch;
  display: flex;
  height: 900px;
  padding: 202px 0px;
  position: relative;
  width: 100%;
}

.LP .container-28 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  margin-bottom: -62.00px;
  margin-top: -62.00px;
  max-width: 960px;
  padding: 0px 0px 0px 32px;
  position: relative;
}

.LP .text-wrapper-49 {
  align-self: stretch;
  color: #8f8f9f;
  font-family: "Switzer-Semibold", Helvetica;
  font-size: 60.9px;
  font-weight: 400;
  letter-spacing: -0.62px;
  line-height: 62px;
  margin-top: -1.00px;
  position: relative;
}

.LP .container-29 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 0;
  position: absolute;
  top: 0;
  width: 928px;
}

.LP .text-wrapper-50 {
  color: #ffffff;
  font-family: "Switzer-Semibold", Helvetica;
  font-size: 60.9px;
  font-weight: 400;
  letter-spacing: -0.62px;
  line-height: 62px;
  margin-top: -1.00px;
  position: relative;
}

.LP .frame-61 {
  align-items: flex-start;
  flex-direction: column;
  position: relative;
  top: 6700px;
  width: calc(100% - 2rem); /* Add margins for padding */
  margin: 4rem auto 0; /* Center align and add spacing */
  position: relative; /* Avoid fixed positioning */
  padding: 1rem; /* Add padding for spacing */
  box-sizing: border-box; /* Include padding in width/height calculations */
}

.LP .container-30 {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center align items */
  gap: 1rem; /* Space between elements */
}

.LP .list-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px; /* Add spacing between items */
  position: relative;
  width: 100%;
}

.LP .list {
  display: flex;
  flex-direction: row; /* Horizontal layout for larger screens */
  gap: 0.5rem; /* Adjust spacing between items */
  justify-content: center; /* Center align */
  flex-wrap: wrap; /* Wrap items on smaller screens */
}

.LP .div-wrapper-2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 16px;
  position: relative;
}

.LP .text-wrapper-51 {
  color: #efedfd99;
  font-family: "Switzer-Regular", Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center; /* Center-align for better spacing */
  width: 100%;
}

.LP a {
  color: inherit;
  text-decoration: none;
}

.LP a:visited {
  color: inherit; /* Ensure links retain their default color after being clicked */
}

.LP a:hover {
  text-decoration: underline;
}

.LP a:active {
  color: inherit;
}

.LP .item {
  color: #efedfd99;
  font-family: "Inter", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.LP .follow-5 {
  align-items: center;
  background: linear-gradient(
    180deg,
    rgba(243, 238, 255, 0) 0%,
    rgba(243, 238, 255, 0.04) 100%
  );
  border-radius: 8px;
  box-shadow: inset 0px 0px 12px #ffffff14;
  display: inline-flex;
  gap: 10px;
  height: 38px;
  padding: 8px 15px;
  position: fixed;
  top: 32px;
  left: calc(100% - 20%); /* Adjust this as needed */
  overflow: hidden;
}

.LP .follow-5 a {
  display: inline-flex; /* Make it behave like a container */
  align-items: center; /* Align items vertically */
  gap: 10px; /* Ensure consistent spacing */
  text-decoration: none; /* Remove underline from the link */
  color: inherit; /* Keep the text color as it was */
}

.LP .group-21 {
  height: 32px;
  position: fixed;
  top: 32px;
  left: calc(100% - 80%); /* Adjust this value for better placement */
  width: 153px;
  transform: translateX(-50%); /* Keeps it positioned relative to the screen */
}

.LP .language-toggle {
  position: fixed;
  top: 32px;
  left: calc(100% - 20% + 145px); /* Positioned next to follow-5 */
  display: flex;
  align-items: center;
  height: 38px; /* Match follow-5 height */
  padding: 0; /* Remove padding from container to let the button handle it */
  background: none; /* No background for container */
}

.LP .language-toggle button {
  background: linear-gradient(
    180deg,
    rgba(243, 238, 255, 0) 0%,
    rgba(243, 238, 255, 0.04) 100%
  );
  border: 1px solid #f3eeff14; /* Match follow-5 border */
  border-radius: 8px;
  color: #ffffff; /* Match text-wrapper-6 text color */
  font-family: "Switzer-Medium", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  cursor: pointer;
  padding: 8px 15px; /* Ensure comfortable click area */
  height: 100%; /* Match parent height */
  width: 100%; /* Ensure full area is clickable */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
  box-shadow: inset 0px 0px 12px #ffffff14; /* Match follow-5 shadow */
}

.LP .language-toggle button:hover {
  border-color: #ffffff; /* Highlight border on hover */
}

}
