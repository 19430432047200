    .PageWrapper {
      background-color: #000212;
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;
    }
    
    .PageWrapper .div {
      background-color: #000212;
      position: relative; /* Create a stacking context */
      height: auto; /* Adjust height dynamically based on content */
      width: 100%; /* Full width */
      overflow-x: hidden; /* Prevent horizontal scrolling */
      padding-bottom: 2rem; /* Ensure some space after footer */
      z-index: 0; /* Default layer */
    }

    .PageWrapper .overlap-8 {
      height: 1173px;
      position: absolute;
      top: 0;
      width: 100%; /* Make it stretch to the full width of the screen */
      max-width: none; /* Ensure no width restrictions */
      left: 0; /* Align to the left edge */
      object-fit: cover; /* Ensure content fills the container */
    }
    
    .PageWrapper .frame-31 {
      height: 506px;
      position: absolute;
      top: 0;
      width: 100%; /* Stretch to full width */
      max-width: none; /* Ensure no width restrictions */
      left: 0; /* Align to the left edge */
      object-fit: cover; /* Maintain aspect ratio and cover */
    }
    
    .PageWrapper .blur-2 {
      height: 737px;
      position: absolute;
      top: 0;
      width: 100%; /* Stretch to full width */
      max-width: none; /* Remove fixed width */
      left: 0; /* Align to the left edge */
      object-fit: cover; /* Ensure it covers the container */
      mix-blend-mode: screen; /* Maintain blend mode */
    }

    .PageWrapper .border {
      border: 1px solid;
      border-color: #f3eeff14;
      border-radius: 8px;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: -1;
    }
    
    .PageWrapper .SVG {
      height: 16px;
      position: relative;
      width: 16px;
    }
    
    .PageWrapper .text-wrapper-6 {
      color: #ffffff;
      font-family: "Switzer-Medium", Helvetica;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 20px;
      position: relative;
      text-align: center;
      white-space: nowrap;
      width: fit-content;
    }
    
    .PageWrapper .container-22 {
      position: relative; /* Ensure it participates in the stacking context */
      z-index: 10; /* Higher z-index to place it above other elements */
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 1.5rem; /* Responsive spacing */
      margin: 4rem auto; /* Add more margin to move down */
      max-width: 90%; /* Responsive width */
      padding: 2rem 1rem; /* Increase padding for better spacing */
      width: 100%;
      box-sizing: border-box; /* Ensure padding is included in width/height */
    }
    
    .PageWrapper .heading-2 {
      align-items: center;
      display: flex;
      flex-direction: column;
      padding: 0 2rem; /* Responsive padding */
      position: relative;
      margin-top: 2rem; /* Add margin to move the title lower */
      width: 100%; /* Stretch to the container width */
    }
    
    .PageWrapper .title {
      color: #f7f8f8;
      font-family: "Switzer-Medium", Helvetica;
      font-size: 2rem; /* Default scalable font size */
      font-weight: 500;
      letter-spacing: -0.05em; /* Reduce spacing slightly */
      line-height: 1.2; /* Keep tight line spacing */
      margin-top: 0.5rem; /* Add a small margin above the title */
      text-align: center;
      white-space: nowrap; /* Prevent text from breaking into multiple lines */
    }
    
      .PageWrapper .content {
        color: #b4bcd0;
        font-family: "Switzer-Regular", Helvetica;
        font-size: 1rem; /* Scalable font size */
        font-weight: 400;
        line-height: 1.5;
        text-align: center;
        max-width: 90%; /* Responsive width */
        margin: 0 auto; /* Center the content */
      }

    .PageWrapper .frame-61 {
      width: calc(100% - 2rem); /* Add margins for padding */
      margin: 4rem auto 0; /* Center align and add spacing */
      position: relative; /* Avoid fixed positioning */
      padding: 1rem; /* Add padding for spacing */
      box-sizing: border-box; /* Include padding in width/height calculations */
    }
    
    .PageWrapper .container-30 {
      display: flex;
      flex-direction: column; /* Stack items vertically */
      align-items: center; /* Center align items */
      gap: 1rem; /* Space between elements */
    }
    
    .PageWrapper .list-wrapper {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 10px; /* Add spacing between items */
      position: relative;
      width: 100%;
    }
    
    .PageWrapper .list {
      display: flex;
      flex-direction: row; /* Horizontal layout for larger screens */
      gap: 0.5rem; /* Adjust spacing between items */
      justify-content: center; /* Center align */
      flex-wrap: wrap; /* Wrap items on smaller screens */
    }
    
    .PageWrapper .div-wrapper-2 {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0 16px;
      position: relative;
    }
    
    .PageWrapper .text-wrapper-51 {
      color: #efedfd99;
      font-family: "Switzer-Regular", Helvetica;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: center; /* Center-align for better spacing */
      width: 100%;
    }
    
    .PageWrapper a {
      color: inherit;
      text-decoration: none;
    }

    .PageWrapper a:visited {
      color: inherit; /* Ensure links retain their default color after being clicked */
    }

    .PageWrapper a:hover {
      text-decoration: underline;
    }

    .PageWrapper a:active {
      color: inherit;
    }

    .PageWrapper .follow-5 {
      align-items: center;
      background: linear-gradient(
        180deg,
        rgba(243, 238, 255, 0) 0%,
        rgba(243, 238, 255, 0.04) 100%
      );
      border-radius: 8px;
      box-shadow: inset 0px 0px 12px #ffffff14;
      display: inline-flex;
      gap: 10px;
      height: 38px;
      padding: 8px 15px;
      position: fixed;
      top: 32px;
      right: 20%; /* 20% from the right for larger screens */
      overflow: hidden;
      z-index: 1000; /* Ensure it stays on top */
      width: auto; /* Adjust width dynamically */
      max-width: calc(100% - 40px); /* Prevent it from going outside the viewport */
    }

    .PageWrapper .follow-5 a {
      display: inline-flex; /* Make it behave like a container */
      align-items: center; /* Align items vertically */
      gap: 10px; /* Ensure consistent spacing */
      text-decoration: none; /* Remove underline from the link */
      color: inherit; /* Keep the text color as it was */
    }
    
    .PageWrapper .group-21 {
      height: 32px;
      position: fixed;
      top: 32px; /* Position from the top */
      left: 20%; /* 20% from the left for larger screens */
      width: 153px; /* Keep its defined width */
      transform: none; /* Remove centering transform */
      z-index: 1000; /* Ensure it stays on top */
    }
    
    .PageWrapper .language-toggle-mobile {
      position: absolute;
      top: 32px; /* Lower the button slightly (adjust as needed) */
      left: 50%; /* Center horizontally */
      transform: translate(-50%, 0); /* Horizontal centering only */
      display: flex;
      align-items: center;
      justify-content: center;
      height: 38px;
      padding: 8px 10px;
      background: linear-gradient(
        180deg,
        rgba(243, 238, 255, 0) 0%,
        rgba(243, 238, 255, 0.04) 100%
      );
      border: 1px solid #f3eeff14; /* Match style */
      border-radius: 8px;
      box-shadow: inset 0px 0px 12px #ffffff14;
      z-index: 1000; /* Ensure it stays above other elements */
    }
    
    
    .PageWrapper .language-toggle-mobile button {
        background: transparent;
        border: none;
        color: #ffffff;
        font-family: "Switzer-Medium", Helvetica, Arial, sans-serif;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        text-align: center;
        white-space: nowrap;
    }

 /* Shared content styling for Privacy and Terms */
.PageWrapper .content-section p:first-of-type {
  margin-bottom: 1rem; /* Add space after the last updated line */
}

.PageWrapper .content-section {
  color: #b4bcd0;
  font-family: "Switzer-Regular", Helvetica;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  max-width: 800px;
  margin: 0 auto;
  padding: 1rem;
  box-sizing: border-box;
}

.PageWrapper .content-section h2 {
  color: #f7f8f8;
  font-size: 1.5rem;
  margin-top: 2rem;
  font-family: "Switzer-Medium", Helvetica;
  font-size: 20.8px;
  font-weight: 500;
  letter-spacing: 0;
}

.PageWrapper .content-section ul {
  list-style-type: disc;
  margin-left: 1.5rem;
  padding-left: 1rem;
  font-size: 1rem; /* Match paragraph font size */
  font-family: "Switzer-Regular", Helvetica;
  color: #b4bcd0;
}

.PageWrapper .content-section ul li {
  margin-bottom: 0.5rem;
}

.PageWrapper .content-section li {
  margin-bottom: 0.5rem;
  font-size: 1rem; /* Match paragraph font size */
  font-family: "Switzer-Regular", Helvetica;
  color: #b4bcd0;
}

/* Shared Email link styling */
.PageWrapper .content-section .email-link {
  color: #ffffff; /* White color for the link */
  text-decoration: none; /* Remove underline by default */
  font-weight: 500; /* Optional: Slightly bolder text */
}

.PageWrapper .content-section .email-link:hover {
  text-decoration: underline; /* Underline on hover */
  color: #ffffff; /* Keep white color on hover */
}

.PageWrapper .content-section .email-link:active {
  color: #ffffff; /* Ensure color remains white when clicked */
}

/* Shared Privacy Policy link styling */
.PageWrapper .content-section .privacy-policy-link {
  color: #ffffff; /* White color for the link */
  text-decoration: none; /* Remove underline by default */
  font-weight: 500; /* Optional: Slightly bolder text */
}

.PageWrapper .content-section .privacy-policy-link:hover {
  text-decoration: underline; /* Underline on hover */
  color: #ffffff; /* Keep white color on hover */
}

.PageWrapper .content-section .privacy-policy-link:active {
  color: #ffffff; /* Ensure color remains white when clicked */
}

/* Media queries */
@media (max-width: 1440px) {
  .PageWrapper .overlap-8,
  .PageWrapper .frame-31,
  .PageWrapper .blur-2 {
    width: 100%; /* Full width for smaller screens */
    height: auto; /* Allow dynamic height */
  }
}

@media (max-width: 1024px) { /* Tablet */     
  .PageWrapper .div {
    padding: 1rem; /* Add inner spacing */
    padding-bottom: 1.5rem; /* Shorten space after footer */
  }

  .PageWrapper .overlap-8 {
    height: 1173px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1440px;
}
  
.PageWrapper .frame-31 {
    height: 506px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1440px;
}
  
.PageWrapper .blur-2 {
    height: 737px;
    left: 0;
    mix-blend-mode: screen;
    position: absolute;
    top: 0;
    width: 1440px;
}

  .PageWrapper .frame-61 {
    width: calc(100% - 2rem); /* Reduce width for padding */
    margin: 3rem auto 0; /* Adjust space above footer */
  }

  .PageWrapper .container-22 {
    margin: 0 auto 3rem; /* Adjust spacing below content */
  }

  .PageWrapper .container-30 {
    gap: 1.5rem; /* Adjust spacing */
  }

  .PageWrapper .list {
    gap: 12px; /* Adjust spacing between list items */
    flex-wrap: wrap; /* Allow items to wrap */
    justify-content: center; /* Center items */
  }

  .PageWrapper .text-wrapper-51 {
    font-size: 13px; /* Adjust font size for smaller screens */
    line-height: 18px; /* Adjust line spacing */
  }

  .PageWrapper .title {
    font-size: 2rem; /* Slightly smaller font for tablets */
    white-space: normal; /* Allow wrapping for smaller screens */
  }

  .PageWrapper .language-toggle-mobile {
    top: 40px; /* Adjust for smaller screens */
  }
}

/* Responsive adjustments for mobile */
@media (max-width: 768px) { /* Mobile */     
  .PageWrapper .div {
    padding: 1rem; /* Additional padding for smaller screens */
    padding-bottom: 1rem; /* Shorter space after footer */
  }

  .PageWrapper .overlap-8 {
    height: 1173px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1440px;
}
  
.PageWrapper .frame-31 {
    height: 506px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1440px;
}
  
.PageWrapper .blur-2 {
    height: 737px;
    left: 0;
    mix-blend-mode: screen;
    position: absolute;
    top: 0;
    width: 1440px;
}

  .PageWrapper .container-22 {
    padding: 1rem 0.5rem; /* Adjust inner spacing */
    margin: 30px auto 2.5rem;
  }

  .PageWrapper .frame-61 {
    width: 100%; /* Full width */
    margin: 2.5rem auto 0; /* Space above footer */
    padding: 1rem 0.5rem; /* Add spacing */
    align-items: center;
  }

  .PageWrapper .list-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 10px; /* Add spacing between items */
    position: relative;
    width: 100%;
  }

  .PageWrapper .list {
    flex-direction: column; /* Stack items vertically */
    gap: 0.5rem; /* Smaller spacing */
    align-items: center;
  }

  .PageWrapper .privacy-policy-link,
  .PageWrapper .terms-and-conditions-link {
    font-size: 12px; /* Smaller font size */
  }

  .PageWrapper .container-30 {
    flex-direction: column; /* Stack items vertically */
    align-items: center;
    padding: 20px 0px 40px; /* Further reduce padding */
  }

  .PageWrapper .text-wrapper-51 {
    font-size: 12px; /* Smaller font size for mobile */
    line-height: 16px; /* Reduce line height */
  }

  .PageWrapper .title {
    font-size: 1.8rem; /* Further reduce font size for mobile */
    white-space: normal; /* Allow wrapping on very small screens */
  }

  .PageWrapper .language-toggle-mobile {
    top: 35px; /* Adjust for even smaller screens */
  }

  .PageWrapper .follow-5 {
    bottom: 20px; /* Stick to the bottom of the viewport */
    right: 10px; /* Keep a few pixels from the right */
    width: auto; /* Maintain dynamic width */
    height: 42px; /* Slightly larger height for touch usability */
    padding: 10px; /* Increase padding for mobile */
  }

  .PageWrapper .group-21 {
    top: 38px;
    left: 10px; /* Keep a few pixels from the left */
    width: auto; /* Allow width to adjust dynamically if needed */
    max-width: 120px; /* Optional: limit width for smaller devices */
  }

  .blogs-overview {
    max-width: 90%;
    margin: 0 auto;
    padding: 1rem;
}

.blogs-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.blog-item {
    margin-bottom: 1.5rem;
    padding-bottom: 1rem;
}

.blog-link {
    text-decoration: none;
    color: inherit;
    display: block;
}

.blog-link h2 {
    font-size: 1.5rem;
    margin: 0;
}

.blog-link p {
    font-size: 1rem;
    margin: 0.5rem 0;
}

.blog-link .blog-date {
    font-size: 0.9rem;
    color: #777;
}

@media (max-width: 768px) {
    .blogs-overview {
        max-width: 95%;
    }

    .blog-link h2 {
        font-size: 1.2rem;
    }

    .blog-link p {
        font-size: 0.9rem;
    }
}
}