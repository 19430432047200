/* Default layout for larger screens */
.cookie-container {
    display: flex;
    justify-content: center; /* Center the buttons horizontally */
    align-items: center; /* Center the buttons vertically within the container */
    flex-wrap: nowrap; /* Prevent buttons from wrapping unless necessary */
  }
  
  /* Apply margin to buttons for spacing */
  .cookie-container button {
    margin: 10px;
  }
  
  @media (max-width: 480px) {
    .cookie-container {
      justify-content: center; /* Horizontally center buttons */
      flex-direction: row; /* Ensure buttons are in a row (horizontal) */
    }
  
    .cookie-container button {
      width: auto; /* Maintain button width instead of full width */
      margin: 10px; /* Keep consistent spacing */
    }
  }