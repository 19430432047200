@media (width >= 769px) {
  .LP {
    background-color: #000212;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    display: flex;
  }

  .LP .div {
    z-index: 0;
    background-color: #000212;
    width: 100%;
    height: 6938px;
    padding-bottom: 2rem;
    position: relative;
    overflow-x: hidden;
  }

  .LP .overlap {
    width: 1440px;
    height: 1552px;
    position: absolute;
    top: 5147px;
    left: 0;
  }

  .LP .gradient {
    width: 1440px;
    height: 1552px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .LP .background {
    background: radial-gradient(50% 50%, #0b0a3b 0%, #0b0a3b36 100%);
    border-radius: 20px;
    align-items: center;
    width: 1202px;
    height: 563px;
    display: flex;
    position: absolute;
    top: 54px;
    left: 119px;
  }

  .LP .container {
    flex-direction: column;
    flex: 1;
    align-items: flex-start;
    display: flex;
    position: relative;
  }

  .LP .container-2 {
    flex-direction: column;
    flex: none;
    align-self: stretch;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
    padding: 0 0 0 108.17px;
    display: flex;
    position: relative;
  }

  .LP .overlay-shadow {
    backdrop-filter: blur(6px) brightness();
    background-color: #ffffff03;
    border-radius: 32px;
    justify-content: center;
    align-items: center;
    width: fit-content;
    padding: 6px 14px;
    display: flex;
    position: relative;
    box-shadow: inset 0 -7px 11px #a48fff1f;
  }

  .LP .div-wrapper {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
  }

  .LP .text-wrapper {
    color: #fff;
    text-align: center;
    white-space: nowrap;
    font-family: Inter, Helvetica;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  .LP .mask-group {
    object-fit: cover;
    width: 93px;
    height: 32px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .LP .container-3 {
    flex-direction: column;
    flex: none;
    align-self: stretch;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
    display: flex;
    position: relative;
  }

  .LP .p {
    color: #f1f1ef;
    letter-spacing: 0;
    width: 493px;
    margin-top: -1px;
    margin-right: -.17px;
    font-family: Switzer-Semibold, Helvetica;
    font-size: 40px;
    font-weight: 400;
    line-height: 48px;
    position: relative;
  }

  .LP .text-wrapper-2 {
    color: #adadad;
    letter-spacing: -.18px;
    align-self: stretch;
    font-family: Switzer-Regular, Helvetica;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    position: relative;
  }

  .LP .group-wrapper {
    flex: 1;
    margin-top: -.23px;
    margin-bottom: -.23px;
    position: relative;
    overflow: hidden;
  }

  .LP .group {
    width: 592px;
    height: 503px;
    position: relative;
    top: 89px;
    left: 76px;
  }

  .LP .overlap-group {
    height: 501px;
    position: relative;
  }

  .LP .overlap-group-wrapper {
    width: 591px;
    height: 501px;
    position: absolute;
    top: 0;
    left: 1px;
  }

  .LP .overlap-group-2 {
    background-image: url("group-15.8be3e80b.png");
    background-size: 100% 100%;
    width: 525px;
    height: 475px;
    position: relative;
  }

  .LP .text-wrapper-3 {
    color: #fff;
    letter-spacing: 0;
    white-space: nowrap;
    font-family: Switzer-Medium, Helvetica;
    font-size: 34.3px;
    font-weight: 500;
    line-height: 37.7px;
    position: absolute;
    top: 72px;
    left: 43px;
  }

  .LP .made-simple {
    width: 206px;
    height: 32px;
    position: absolute;
    top: 107px;
    left: 46px;
  }

  .LP .img {
    width: 523px;
    height: 473px;
    position: absolute;
    top: 2px;
    left: 3px;
  }

  .LP .group-2 {
    width: 11px;
    height: 7px;
    position: absolute;
    top: 164px;
    left: 45px;
  }

  .LP .group-3 {
    width: 12px;
    height: 7px;
    position: absolute;
    top: 228px;
    left: 45px;
  }

  .LP .group-4 {
    width: 12px;
    height: 7px;
    position: absolute;
    top: 293px;
    left: 45px;
  }

  .LP .group-5 {
    width: 12px;
    height: 7px;
    position: absolute;
    top: 360px;
    left: 45px;
  }

  .LP .group-6 {
    width: 446px;
    height: 1px;
    position: absolute;
    top: 167px;
    left: 71px;
  }

  .LP .group-7 {
    width: 446px;
    height: 1px;
    position: absolute;
    top: 231px;
    left: 71px;
  }

  .LP .group-8 {
    width: 446px;
    height: 1px;
    position: absolute;
    top: 296px;
    left: 71px;
  }

  .LP .group-9 {
    width: 446px;
    height: 1px;
    position: absolute;
    top: 364px;
    left: 71px;
  }

  .LP .group-10 {
    width: 455px;
    height: 230px;
    position: absolute;
    top: 145px;
    left: 70px;
  }

  .LP .group-11 {
    width: 52px;
    height: 35px;
    position: absolute;
    top: 187px;
    left: 464px;
  }

  .LP .group-12 {
    width: 52px;
    height: 32px;
    position: absolute;
    top: 187px;
    left: 465px;
  }

  .LP .group-13 {
    width: 451px;
    height: 196px;
    position: absolute;
    top: 180px;
    left: 74px;
  }

  .LP .group-14 {
    width: 2px;
    height: 54px;
    position: absolute;
    top: 23px;
    left: 0;
  }

  .LP .group-15 {
    width: 524px;
    height: 174px;
    position: absolute;
    top: 300px;
    left: 2px;
  }

  .LP .clip-path-group {
    width: 13px;
    height: 21px;
    position: absolute;
    top: 93px;
    left: 204px;
  }

  .LP .group-16 {
    width: 49px;
    height: 22px;
    position: absolute;
    top: 26px;
    left: 41px;
  }

  .LP .group-17 {
    width: 49px;
    height: 22px;
    position: absolute;
    top: 187px;
    left: 320px;
  }

  .LP .group-18 {
    width: 2px;
    height: 141px;
    position: absolute;
    top: 231px;
    left: 344px;
  }

  .LP .group-19 {
    width: 39px;
    height: 38px;
    position: absolute;
    top: 213px;
    left: 325px;
  }

  .LP .text-wrapper-4 {
    color: #fff;
    letter-spacing: 0;
    opacity: .5;
    white-space: nowrap;
    font-family: Switzer-Regular, Helvetica;
    font-size: 10px;
    font-weight: 400;
    line-height: 11px;
    position: absolute;
    top: 32px;
    left: 59px;
  }

  .LP .container-4 {
    flex-direction: column;
    align-items: center;
    gap: 36px;
    width: 1024px;
    display: flex;
    position: absolute;
    top: 861px;
    left: 208px;
  }

  .LP .icon {
    background: linear-gradient(#6c53b7 0%, #121340 78%, #070722 100%);
    border-radius: 19px;
    width: 83.52px;
    height: 83.52px;
    position: relative;
  }

  .LP .union {
    width: 30px;
    height: 42px;
    position: absolute;
    top: 21px;
    left: 26px;
  }

  .LP .heading-wrapper {
    flex-direction: column;
    flex: none;
    align-self: stretch;
    align-items: center;
    gap: 40px;
    width: 100%;
    display: flex;
    position: relative;
  }

  .LP .heading {
    flex-direction: column;
    flex: none;
    align-items: center;
    margin-left: -72.43px;
    margin-right: -72.43px;
    padding: 0 1.14e-13px 10.4px 0;
    display: inline-flex;
    position: relative;
  }

  .LP .upgrade-to-the-new {
    -webkit-text-fill-color: transparent;
    color: #0000;
    letter-spacing: -.8px;
    text-align: center;
    background: radial-gradient(50% 50%, #fff9 0%, #fff 100%) text;
    width: fit-content;
    max-width: 1050px;
    height: 152px;
    margin-top: -1px;
    font-family: Switzer-Medium, Helvetica;
    font-size: 72px;
    font-weight: 500;
    line-height: 72px;
    position: relative;
    -webkit-background-clip: text !important;
  }

  .LP .follow {
    z-index: 1;
    background-color: #fff;
    border-radius: 8px;
    align-items: center;
    gap: 10px;
    height: 38px;
    margin-bottom: 20px;
    padding: 8px 15px;
    display: inline-flex;
    position: relative;
  }

  .LP .follow a {
    color: inherit;
    align-items: center;
    gap: 10px;
    text-decoration: none;
    display: inline-flex;
  }

  .LP .border {
    z-index: -1;
    border: 1px solid #f3eeff14;
    border-radius: 8px;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .LP .SVG {
    width: 16px;
    height: 16px;
    position: relative;
  }

  .LP .text-wrapper-5 {
    -webkit-text-fill-color: transparent;
    color: #0000;
    letter-spacing: 0;
    text-align: center;
    white-space: nowrap;
    background: linear-gradient(#121340 0%, #6c53b7 100%) text;
    width: fit-content;
    font-family: Switzer-Medium, Helvetica;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    position: relative;
    -webkit-background-clip: text !important;
  }

  .LP .follow-2 {
    background: linear-gradient(#f3eeff00 0%, #f3eeff0a 100%);
    border-radius: 8px;
    align-items: center;
    gap: 10px;
    width: fit-content;
    height: auto;
    padding: 8px 12px;
    display: inline-flex;
    position: relative;
    top: 1403px;
    left: 1001px;
    box-shadow: inset 0 0 12px #ffffff14;
  }

  .LP .content-wrapper {
    align-items: center;
    gap: 8px;
    display: flex;
  }

  .LP .border-2 {
    z-index: -1;
    border: 1px solid #f3eeff14;
    border-radius: 8px;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .LP .image {
    flex-shrink: 0;
    width: 22px;
    height: 22px;
  }

  .LP .text-container {
    white-space: nowrap;
    flex-wrap: nowrap;
    font-family: Inter, sans-serif;
    font-size: 14px;
    line-height: 20px;
  }

  .LP .white-text {
    color: #fff;
    font-weight: 500;
  }

  .LP .light-purple-text {
    color: #d3c4f3;
    font-weight: 400;
  }

  .LP .follow-3 {
    background: linear-gradient(#f3eeff00 0%, #f3eeff0a 100%);
    border-radius: 8px;
    align-items: center;
    gap: 10px;
    height: 38px;
    padding: 8px 15px;
    display: inline-flex;
    position: absolute;
    top: 1451px;
    left: 1162px;
    box-shadow: inset 0 0 12px #ffffff14;
  }

  .LP .follow-3 a {
    color: inherit;
    align-items: center;
    gap: 10px;
    text-decoration: none;
    display: inline-flex;
  }

  .LP .text-wrapper-6 {
    color: #fff;
    letter-spacing: 0;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
    font-family: Switzer-Medium, Helvetica;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    position: relative;
  }

  .LP .text-wrapper-7 {
    color: #efedfd;
    letter-spacing: 0;
    width: 359px;
    height: 20px;
    font-family: Switzer-Regular, Helvetica;
    font-size: 40px;
    font-weight: 400;
    line-height: 20px;
    position: absolute;
    top: 1485px;
    left: 156px;
  }

  .LP .text-wrapper-7 a {
    color: #efedfd;
    text-decoration: none;
  }

  .LP .text-wrapper-7 a:hover {
    text-decoration: underline;
  }

  .LP .logo {
    width: 153px;
    height: 32px;
    position: absolute;
    top: 1405px;
    left: 156px;
  }

  .LP .overlap-2 {
    width: 1447px;
    height: 1160px;
    position: absolute;
    top: 2843px;
    left: 0;
  }

  .LP .group-20 {
    width: 989px;
    height: 524px;
    position: absolute;
    top: 236px;
    left: 233px;
  }

  .LP .rectangle {
    background: linear-gradient(#000212 0%, #000212c7 45%, #00021200 100%);
    width: 1419px;
    height: 731px;
    position: absolute;
    top: 39px;
    left: 28px;
    transform: rotate(180deg);
  }

  .LP .blur {
    width: 1440px;
    height: 932px;
    position: absolute;
    top: 24px;
    left: 0;
  }

  .LP .follow-4 {
    background-color: #fff;
    border-radius: 8px;
    align-items: center;
    gap: 10px;
    height: 38px;
    padding: 8px 15px;
    display: inline-flex;
    position: absolute;
    top: 745px;
    left: 659px;
  }

  .LP .follow-4 a {
    color: inherit;
    align-items: center;
    gap: 10px;
    text-decoration: none;
    display: inline-flex;
  }

  .LP .iphone-pro {
    width: 331px;
    height: 680px;
    position: absolute;
    top: 0;
    left: 555px;
  }

  .LP .overlap-3 {
    height: 673px;
    position: relative;
  }

  .LP .overlap-4 {
    width: 393px;
    height: 852px;
    position: relative;
  }

  .LP .frame {
    border-radius: 11.45px;
    width: 280px;
    height: 534px;
    position: absolute;
    top: 37px;
    left: 10px;
    overflow: hidden;
  }

  .LP .overlap-5 {
    width: 368px;
    height: 794px;
    position: relative;
  }

  .LP .frame-wrapper {
    justify-content: space-around;
    align-items: flex-end;
    gap: 187.01px;
    width: 247px;
    display: flex;
    position: absolute;
    top: 16px;
    left: 14px;
  }

  .LP .silver {
    object-fit: cover;
    width: 331px;
    height: 680px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .LP .container-wrapper {
    flex-direction: column;
    align-items: center;
    gap: 38px;
    width: 1440px;
    display: flex;
    position: absolute;
    top: 832px;
    left: 0;
  }

  .LP .container-5 {
    width: 900px;
    height: 328px;
    position: relative;
  }

  .LP .overlap-6 {
    width: 948px;
    height: 164px;
    position: absolute;
    top: 164px;
    left: -24px;
  }

  .LP .horizontal-divider {
    background: linear-gradient(#fff0 0%, #ffffff14 24.33% 49.55%, #ffffff14 74.78%, #fff0 100%);
    width: 948px;
    height: 1px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .LP .overlap-wrapper {
    width: 300px;
    height: 164px;
    position: absolute;
    top: 0;
    left: 174px;
  }

  .LP .overlap-7 {
    height: 164px;
    position: relative;
  }

  .LP .div-2 {
    width: 300px;
    height: 164px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .LP .container-6 {
    flex-direction: column;
    align-items: center;
    width: 300px;
    padding: 0 67.6px 0 67.59px;
    display: flex;
    position: absolute;
    top: 84px;
    left: 0;
  }

  .LP .text-wrapper-28 {
    color: #f4f0ff;
    letter-spacing: 0;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
    margin-top: -1px;
    font-family: Inter, Helvetica;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    position: relative;
  }

  .LP .container-7 {
    flex-direction: column;
    align-items: center;
    width: 300px;
    padding: 0 56.5px 0 56.48px;
    display: flex;
    position: absolute;
    top: 108px;
    left: 0;
  }

  .LP .text-wrapper-29 {
    color: #efedfd99;
    letter-spacing: 0;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
    margin-top: -1px;
    font-family: Inter, Helvetica;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    position: relative;
  }

  .LP .vertical-divider {
    background: linear-gradient(#ffffff1f 0%, #fff0 100%);
    width: 1px;
    height: 164px;
    position: absolute;
    top: 0;
    left: 299px;
  }

  .LP .frame-26 {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 30px;
    left: 135px;
  }

  .LP .container-8 {
    width: 300px;
    height: 164px;
    position: absolute;
    top: 0;
    left: 474px;
  }

  .LP .container-9 {
    flex-direction: column;
    align-items: center;
    width: 300px;
    padding: 0 94.59px 0 94.58px;
    display: flex;
    position: absolute;
    top: 84px;
    left: 0;
  }

  .LP .text-wrapper-30 {
    color: #f4f0ff;
    letter-spacing: 0;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
    margin-top: -1px;
    margin-left: -18.08px;
    margin-right: -18.08px;
    font-family: Inter, Helvetica;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    position: relative;
  }

  .LP .container-10 {
    flex-direction: column;
    align-items: center;
    width: 300px;
    padding: 0 88.52px 0 88.5px;
    display: flex;
    position: absolute;
    top: 108px;
    left: 0;
  }

  .LP .text-wrapper-31 {
    color: #efedfd99;
    letter-spacing: 0;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
    margin-top: -1px;
    margin-left: -44.51px;
    margin-right: -44.51px;
    font-family: Inter, Helvetica;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    position: relative;
  }

  .LP .frame-27 {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 32px;
    left: 135px;
  }

  .LP .receipt-capture-wrapper {
    flex-direction: column;
    align-items: center;
    width: 300px;
    padding: 0 64.22px 0 64.2px;
    display: flex;
    position: absolute;
    top: 84px;
    left: 0;
  }

  .LP .receipt-capture {
    color: #f4f0ff;
    letter-spacing: 0;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
    margin-top: -1px;
    margin-left: -12.71px;
    margin-right: -12.71px;
    font-family: Inter, Helvetica;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    position: relative;
  }

  .LP .container-11 {
    flex-direction: column;
    align-items: center;
    width: 300px;
    padding: 0 48.33px 0 48.31px;
    display: flex;
    position: absolute;
    top: 108px;
    left: 0;
  }

  .LP .text-wrapper-32 {
    color: #efedfd99;
    letter-spacing: 0;
    text-align: center;
    width: 204px;
    margin-top: -1px;
    margin-left: -.32px;
    margin-right: -.32px;
    font-family: Inter, Helvetica;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    position: relative;
  }

  .LP .frame-28 {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 35px;
    left: 135px;
  }

  .LP .container-12 {
    width: 300px;
    height: 164px;
    position: absolute;
    top: 0;
    left: 300px;
  }

  .LP .frame-29 {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 28px;
    left: 135px;
  }

  .LP .organize-track-wrapper {
    flex-direction: column;
    align-items: center;
    width: 300px;
    padding: 0 57.5px;
    display: flex;
    position: absolute;
    top: 84px;
    left: 0;
  }

  .LP .container-13 {
    flex-direction: column;
    align-items: center;
    width: 300px;
    padding: 0 39.2px 0 39.19px;
    display: flex;
    position: absolute;
    top: 108px;
    left: 0;
  }

  .LP .container-14 {
    width: 300px;
    height: 164px;
    position: absolute;
    top: 0;
    left: 600px;
  }

  .LP .container-15 {
    flex-direction: column;
    align-items: center;
    width: 300px;
    padding: 0 36.83px 0 36.81px;
    display: flex;
    position: absolute;
    top: 84px;
    left: 0;
  }

  .LP .container-16 {
    flex-direction: column;
    align-items: center;
    width: 300px;
    padding: 0 34.54px 0 34.55px;
    display: flex;
    position: absolute;
    top: 108px;
    left: 0;
  }

  .LP .frame-30 {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 29px;
    left: 135px;
  }

  .LP .overlap-8 {
    object-fit: cover;
    width: 100%;
    max-width: none;
    height: 1173px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .LP .frame-31 {
    object-fit: cover;
    z-index: 0;
    pointer-events: none;
    width: 100%;
    max-width: none;
    height: 506px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .LP .blur-2 {
    object-fit: cover;
    mix-blend-mode: screen;
    z-index: 0;
    pointer-events: none;
    width: 100%;
    max-width: none;
    height: 737px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .LP .margin {
    flex-direction: column;
    align-items: center;
    width: 1376px;
    padding: 0 144px;
    display: flex;
    position: absolute;
    top: 48px;
    left: 32px;
  }

  .LP .container-17 {
    flex-direction: column;
    flex: none;
    align-items: center;
    width: 1088px;
    max-width: 1088px;
    padding: 64px 32px 0;
    display: flex;
    position: relative;
  }

  .LP .container-18 {
    flex-direction: column;
    flex: none;
    align-self: stretch;
    align-items: center;
    width: 100%;
    display: flex;
    position: relative;
  }

  .LP .heading-margin {
    flex-direction: column;
    flex: none;
    align-items: flex-start;
    padding: 24px 0;
    display: inline-flex;
    position: relative;
  }

  .LP .save-time-and-money sup {
    vertical-align: top;
    font-size: .65em;
    line-height: 1;
  }

  .LP .save-time-and-money {
    color: #d9d9d9;
    letter-spacing: -1.6px;
    text-align: center;
    width: fit-content;
    margin-top: -1px;
    font-family: Switzer-Medium, Helvetica;
    font-size: 80px;
    font-weight: 500;
    line-height: 80px;
    position: relative;
  }

  .LP .margin-2 {
    flex-direction: column;
    flex: none;
    align-items: flex-start;
    padding: 0 0 48px;
    display: inline-flex;
    position: relative;
  }

  .LP .container-19 {
    flex-direction: column;
    flex: none;
    align-items: center;
    padding: 0 220.67px;
    display: inline-flex;
    position: relative;
  }

  .LP .text-wrapper-33 {
    color: #b4bcd0;
    letter-spacing: 0;
    text-align: center;
    width: 546px;
    margin-top: -1px;
    font-family: Switzer-Regular, Helvetica;
    font-size: 21px;
    font-weight: 400;
    line-height: 28.6px;
    position: relative;
  }

  .LP .iphone-pro-mockup {
    width: 100%;
    height: auto;
    position: absolute;
    top: 478px;
    left: auto;
  }

  .LP .container-20 {
    align-items: center;
    gap: 24px;
    width: 1088px;
    max-width: 1088px;
    padding: 0 32px;
    display: flex;
    position: absolute;
    top: 1898px;
    left: 176px;
  }

  .LP .frame-32 {
    width: 500px;
    height: 455.34px;
    position: relative;
    overflow: hidden;
  }

  .LP .overlap-9 {
    height: 1021px;
    position: relative;
  }

  .LP .background-2 {
    background: radial-gradient(50% 50%, #6663f6 0%, #0000 100%);
    border-radius: 48px;
    flex-direction: column;
    align-items: flex-start;
    width: 500px;
    padding: 32px 48px 311.34px;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
  }

  .LP .heading-margin-2 {
    flex-direction: column;
    flex: none;
    align-self: stretch;
    align-items: flex-start;
    width: 100%;
    padding: 0 0 8px;
    display: flex;
    position: relative;
  }

  .LP .div-3 {
    flex-direction: column;
    flex: none;
    align-self: stretch;
    align-items: flex-start;
    width: 100%;
    display: flex;
    position: relative;
  }

  .LP .text-wrapper-34 {
    color: #f7f8f8;
    letter-spacing: -.24px;
    align-self: stretch;
    margin-top: -1px;
    font-family: Switzer-Medium, Helvetica;
    font-size: 27px;
    font-weight: 500;
    line-height: 31.2px;
    position: relative;
  }

  .LP .container-21 {
    width: fit-content;
    max-width: 345px;
    margin-top: --13.1e-13px;
    flex-direction: column;
    flex: none;
    align-self: stretch;
    align-items: flex-start;
    display: flex;
    position: relative;
  }

  .LP .text-wrapper-35 {
    color: #b4bcd0;
    letter-spacing: 0;
    align-self: stretch;
    margin-top: -1px;
    font-family: Switzer-Medium, Helvetica;
    font-size: 15.8px;
    font-weight: 500;
    line-height: 24px;
    position: relative;
  }

  .LP .mask-group-2 {
    object-fit: cover;
    width: 500px;
    height: 455px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .LP .archive-3 {
    backdrop-filter: blur(30px) brightness();
    background: linear-gradient(#12134080 0%, #2e31a600 100%);
    border-color: #0000;
    border-image: linear-gradient(#fff0, #fff3 50.5%, #fff0 100%) 1;
    border-radius: 53px;
    justify-content: center;
    align-items: flex-start;
    width: 404px;
    height: 848px;
    display: flex;
    position: absolute;
    top: 173px;
    left: 48px;
    overflow: hidden;
  }

  .LP .frame-33 {
    object-fit: contain;
    width: 100%;
    height: auto;
    max-height: 100%;
  }

  .LP .frame-34 {
    width: 377px;
    height: 50px;
    position: absolute;
    top: 238px;
    left: 0;
  }

  .LP .frame-35 {
    width: 41px;
    height: 37px;
    position: absolute;
    top: 5px;
    left: 15px;
  }

  .LP .transactions-2 {
    width: 57px;
    height: 37px;
    position: relative;
    left: -6px;
  }

  .LP .frame-36 {
    width: 17px;
    height: 17px;
    position: absolute;
    top: -1833px;
    left: -325px;
  }

  .LP .frame-37 {
    flex-direction: column;
    align-items: center;
    gap: 6.17px;
    width: 46px;
    display: flex;
    position: relative;
  }

  .LP .frame-38 {
    width: 17.48px;
    height: 17.48px;
    margin-top: -1833.48px;
    margin-left: -401.38px;
    position: relative;
  }

  .LP .frame-39 {
    width: 46px;
    height: 46px;
    position: absolute;
    top: -1829px;
    left: -162px;
  }

  .LP .frame-40 {
    width: 17.48px;
    height: 17.48px;
    margin-top: -1833.48px;
    margin-left: -554.55px;
    position: relative;
  }

  .LP .frame-41 {
    width: 16.45px;
    height: 16.45px;
    margin-top: -1833.48px;
    margin-left: -628.82px;
    position: relative;
  }

  .LP .frame-42 {
    border: 1.03px solid #ffffff1a;
    border-radius: 205.6px;
    justify-content: space-between;
    align-items: center;
    width: 114px;
    padding: 10.28px 20.56px;
    display: flex;
    position: absolute;
    top: 80px;
    left: 21px;
  }

  .LP .frame-43 {
    border: 1.03px solid #ffffff1a;
    border-radius: 205.6px;
    justify-content: space-between;
    align-items: center;
    width: 113px;
    padding: 10.28px 20.56px;
    display: flex;
    position: absolute;
    top: 80px;
    left: 145px;
  }

  .LP .frame-44 {
    border: 1.03px solid #ffffff1a;
    border-radius: 205.6px;
    justify-content: space-between;
    align-items: center;
    width: 114px;
    padding: 10.28px 20.56px;
    display: flex;
    position: absolute;
    top: 80px;
    left: 268px;
  }

  .LP .frame-45 {
    width: 57px;
    height: 25px;
    position: absolute;
    top: 32px;
    left: 37px;
  }

  .LP .frame-46 {
    flex-wrap: wrap;
    align-items: center;
    gap: 2px 2.06px;
    width: 361px;
    display: flex;
    position: absolute;
    top: 81px;
    left: 22px;
  }

  .LP .frame-47 {
    backdrop-filter: blur(4px) brightness();
    background: linear-gradient(#12134080 0%, #6c53b780 100%);
    border-radius: 20px;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    align-items: flex-start;
    height: 173px;
    padding: 20px;
    display: flex;
    position: relative;
  }

  .LP .frame-48 {
    flex: none;
    align-items: center;
    gap: 5px;
    display: inline-flex;
    position: relative;
  }

  .LP .frame-49 {
    object-fit: contain;
    width: 100%;
    height: auto;
    max-height: 100%;
  }

  .LP .container-22 {
    flex-direction: column;
    align-items: center;
    gap: 23.69px;
    width: 1088px;
    max-width: 1088px;
    padding: 0 32px;
    display: flex;
    position: absolute;
    top: 1483px;
    left: 176px;
  }

  .LP .mask-group-3 {
    object-fit: cover;
    width: 97px;
    height: 32px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .LP .heading-2 {
    flex-direction: column;
    flex: none;
    align-self: stretch;
    align-items: center;
    width: 100%;
    padding: 0 287.37px 0 287.36px;
    display: flex;
    position: relative;
  }

  .LP .text-wrapper-46 {
    color: #f7f8f8;
    letter-spacing: -1.12px;
    text-align: center;
    align-self: stretch;
    margin-top: -1px;
    font-family: Switzer-Medium, Helvetica;
    font-size: 51.4px;
    font-weight: 500;
    line-height: 56px;
    position: relative;
  }

  .LP .in-today-s-fast {
    color: #b4bcd0;
    letter-spacing: 0;
    text-align: center;
    width: 470px;
    font-family: Switzer-Regular, Helvetica;
    font-size: 21.1px;
    font-weight: 400;
    line-height: 28.6px;
    position: relative;
  }

  .LP .container-23 {
    width: 1440px;
    height: 168px;
    position: absolute;
    top: 2563px;
    left: 0;
  }

  .LP .heading-3 {
    flex-direction: column;
    align-items: center;
    width: 1440px;
    padding: 0 390.12px 0 390.13px;
    display: flex;
    position: absolute;
    top: 44px;
    left: 0;
  }

  .LP .text-wrapper-47 {
    -webkit-text-fill-color: transparent;
    color: #0000;
    letter-spacing: 0;
    text-align: center;
    white-space: nowrap;
    background: linear-gradient(#fff 0%, #ffffffb3 100%) text;
    width: fit-content;
    margin-top: -1px;
    margin-left: -3.12px;
    margin-right: -3.12px;
    font-family: Switzer-Regular, Helvetica;
    font-size: 56px;
    font-weight: 400;
    line-height: 64px;
    position: relative;
    -webkit-background-clip: text !important;
  }

  .LP .container-24 {
    flex-direction: column;
    align-items: center;
    width: 744px;
    max-width: 744px;
    padding: 0 108.02px 0 108px;
    display: flex;
    position: absolute;
    top: 120px;
    left: 348px;
  }

  .LP .text-wrapper-48 {
    color: #efedfdb2;
    letter-spacing: 0;
    text-align: center;
    width: 528px;
    margin-top: -1px;
    margin-left: -.01px;
    margin-right: -.01px;
    font-family: Inter, Helvetica;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    position: relative;
  }

  .LP .overlay-shadow-2 {
    backdrop-filter: blur(6px) brightness();
    background-color: #ffffff01;
    border-radius: 32px;
    align-items: center;
    padding: 6px 14px 6px 15px;
    display: inline-flex;
    position: absolute;
    top: 0;
    left: 676px;
    overflow: hidden;
    box-shadow: inset 0 -7px 11px #a48fff1f;
  }

  .LP .mask-group-4 {
    object-fit: cover;
    width: 88px;
    height: 32px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .LP .section {
    flex-direction: column;
    align-items: center;
    width: 1440px;
    padding: 0 52.26px 0 52.27px;
    display: flex;
    position: absolute;
    top: 4161px;
    left: 0;
  }

  .LP .container-25 {
    flex-direction: column;
    flex: none;
    align-items: flex-start;
    width: 1266px;
    max-width: 1266px;
    padding: 0 32px;
    display: flex;
    position: relative;
  }

  .LP .container-26 {
    flex-direction: column;
    flex: none;
    justify-content: center;
    align-self: stretch;
    align-items: flex-start;
    width: 100%;
    display: flex;
    position: relative;
  }

  .LP .container-27 {
    align-self: stretch;
    align-items: center;
    width: 100%;
    height: 900px;
    padding: 202px 0;
    display: flex;
    position: relative;
  }

  .LP .container-28 {
    flex-direction: column;
    flex: none;
    align-items: flex-start;
    max-width: 960px;
    margin-top: -62px;
    margin-bottom: -62px;
    padding: 0 0 0 32px;
    display: inline-flex;
    position: relative;
  }

  .LP .text-wrapper-49 {
    color: #8f8f9f;
    letter-spacing: -.62px;
    align-self: stretch;
    margin-top: -1px;
    font-family: Switzer-Semibold, Helvetica;
    font-size: 60.9px;
    font-weight: 400;
    line-height: 62px;
    position: relative;
  }

  .LP .container-29 {
    flex-direction: column;
    align-items: flex-start;
    width: 928px;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
  }

  .LP .text-wrapper-50 {
    color: #fff;
    letter-spacing: -.62px;
    margin-top: -1px;
    font-family: Switzer-Semibold, Helvetica;
    font-size: 60.9px;
    font-weight: 400;
    line-height: 62px;
    position: relative;
  }

  .LP .frame-61 {
    box-sizing: border-box;
    flex-direction: column;
    align-items: flex-start;
    width: calc(100% - 2rem);
    margin: 4rem auto 0;
    padding: 1rem;
    position: relative;
    top: 6700px;
  }

  .LP .container-30 {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    display: flex;
  }

  .LP .list-wrapper {
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;
    display: flex;
    position: relative;
  }

  .LP .list {
    flex-flow: wrap;
    justify-content: center;
    gap: .5rem;
    display: flex;
  }

  .LP .div-wrapper-2 {
    flex-direction: column;
    align-items: flex-start;
    padding: 0 16px;
    display: flex;
    position: relative;
  }

  .LP .text-wrapper-51 {
    color: #efedfd99;
    text-align: center;
    width: 100%;
    font-family: Switzer-Regular, Helvetica;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .LP a {
    color: inherit;
    text-decoration: none;
  }

  .LP a:visited {
    color: inherit;
  }

  .LP a:hover {
    text-decoration: underline;
  }

  .LP a:active {
    color: inherit;
  }

  .LP .item {
    color: #efedfd99;
    letter-spacing: 0;
    white-space: nowrap;
    width: fit-content;
    margin-top: -1px;
    font-family: Inter, Helvetica;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    position: relative;
  }

  .LP .follow-5 {
    background: linear-gradient(#f3eeff00 0%, #f3eeff0a 100%);
    border-radius: 8px;
    align-items: center;
    gap: 10px;
    height: 38px;
    padding: 8px 15px;
    display: inline-flex;
    position: fixed;
    top: 32px;
    left: 80%;
    overflow: hidden;
    box-shadow: inset 0 0 12px #ffffff14;
  }

  .LP .follow-5 a {
    color: inherit;
    align-items: center;
    gap: 10px;
    text-decoration: none;
    display: inline-flex;
  }

  .LP .group-21 {
    width: 153px;
    height: 32px;
    position: fixed;
    top: 32px;
    left: 20%;
    transform: translateX(-50%);
  }

  .LP .language-toggle {
    background: none;
    align-items: center;
    height: 38px;
    padding: 0;
    display: flex;
    position: fixed;
    top: 32px;
    left: calc(80% + 145px);
  }

  .LP .language-toggle button {
    color: #fff;
    letter-spacing: 0;
    cursor: pointer;
    text-align: center;
    white-space: nowrap;
    background: linear-gradient(#f3eeff00 0%, #f3eeff0a 100%);
    border: 1px solid #f3eeff14;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 8px 15px;
    font-family: Switzer-Medium, Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    display: flex;
    box-shadow: inset 0 0 12px #ffffff14;
  }

  .LP .language-toggle button:hover {
    border-color: #fff;
  }
}

@media (width <= 768px) {
  .iphone {
    background-color: #000212;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    display: flex;
  }

  .iphone .div-3 {
    background-color: #000212;
    width: 390px;
    height: 6180px;
    position: relative;
    overflow: hidden;
  }

  .iphone .overlap {
    width: 1419px;
    height: 1431px;
    position: absolute;
    top: 2400px;
    left: -771px;
  }

  .iphone .group {
    width: 390px;
    height: 524px;
    position: absolute;
    top: 227px;
    left: 771px;
  }

  .iphone .rectangle-4 {
    background: linear-gradient(#000212 0%, #000212c7 45%, #00021200 100%);
    width: 1419px;
    height: 731px;
    position: absolute;
    top: 30px;
    left: 0;
    transform: rotate(180deg);
  }

  .iphone .blur {
    width: 390px;
    height: 932px;
    position: absolute;
    top: 15px;
    left: 771px;
  }

  .iphone .iphone-pro {
    width: 234px;
    height: 480px;
    position: absolute;
    top: 0;
    left: 851px;
  }

  .iphone .photo-transaction {
    background-color: var(--midnight-indigo);
    border-radius: 16.99px;
    width: 212px;
    height: 460px;
    position: absolute;
    top: 8px;
    left: 11px;
    overflow: hidden;
  }

  .iphone .iphone-lements {
    width: 393px;
    height: 852px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .iphone .header-text {
    flex-direction: column;
    flex: none;
    align-items: flex-start;
    gap: 3px;
    width: 156.67px;
    display: flex;
    position: relative;
  }

  .iphone .p {
    color: #474747;
    letter-spacing: 0;
    text-align: center;
    align-self: stretch;
    font-family: Switzer-Regular, Helvetica;
    font-size: 7px;
    font-weight: 400;
    line-height: 11px;
    position: relative;
  }

  .iphone .amount {
    flex-direction: column;
    flex: none;
    align-items: flex-start;
    gap: 3px;
    display: inline-flex;
    position: relative;
  }

  .iphone .payment-details {
    flex-direction: column;
    flex: none;
    align-self: stretch;
    align-items: flex-start;
    gap: 6.01px;
    width: 100%;
    display: flex;
    position: relative;
  }

  .iphone .payment-detail {
    border: .5px solid #ededed;
    border-radius: 3px;
    flex-direction: column;
    flex: 1;
    align-items: flex-start;
    gap: 2px;
    padding: 6.01px;
    display: flex;
    position: relative;
  }

  .iphone .silver {
    object-fit: cover;
    width: 234px;
    height: 480px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .iphone .frame-37 {
    flex-direction: column;
    align-items: flex-start;
    width: 300px;
    display: flex;
    position: absolute;
    top: 611px;
    left: 816px;
  }

  .iphone .container {
    align-self: stretch;
    width: 100%;
    height: 164px;
    position: relative;
  }

  .iphone .overlap-5 {
    width: 300px;
    height: 164px;
    position: relative;
  }

  .iphone .overlap-group-5 {
    width: 300px;
    height: 164px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .iphone .receipt-capture-wrapper {
    flex-direction: column;
    align-items: center;
    width: 300px;
    padding: 0 64.22px 0 64.2px;
    display: flex;
    position: absolute;
    top: 84px;
    left: 0;
  }

  .iphone .receipt-capture {
    color: #f4f0ff;
    letter-spacing: 0;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
    margin-top: -1px;
    margin-left: -12.71px;
    margin-right: -12.71px;
    font-family: Inter, Helvetica;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    position: relative;
  }

  .iphone .container-2 {
    flex-direction: column;
    align-items: center;
    width: 300px;
    padding: 0 48.33px 0 48.31px;
    display: flex;
    position: absolute;
    top: 108px;
    left: 0;
  }

  .iphone .text-wrapper-22 {
    color: #efedfd99;
    letter-spacing: 0;
    text-align: center;
    width: 204px;
    margin-top: -1px;
    margin-left: -.32px;
    margin-right: -.32px;
    font-family: Inter, Helvetica;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    position: relative;
  }

  .iphone .frame-38 {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 35px;
    left: 135px;
  }

  .iphone .frame-39 {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 28px;
    left: 135px;
  }

  .iphone .organize-track-wrapper {
    flex-direction: column;
    align-items: center;
    width: 300px;
    padding: 0 57.5px;
    display: flex;
    position: absolute;
    top: 84px;
    left: 0;
  }

  .iphone .text-wrapper-23 {
    color: #f4f0ff;
    letter-spacing: 0;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
    margin-top: -1px;
    font-family: Inter, Helvetica;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    position: relative;
  }

  .iphone .container-3 {
    flex-direction: column;
    align-items: center;
    width: 300px;
    padding: 0 39.2px 0 39.19px;
    display: flex;
    position: absolute;
    top: 108px;
    left: 0;
  }

  .iphone .text-wrapper-24 {
    color: #efedfd99;
    letter-spacing: 0;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
    margin-top: -1px;
    font-family: Inter, Helvetica;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    position: relative;
  }

  .iphone .container-4 {
    flex-direction: column;
    align-items: center;
    width: 300px;
    padding: 0 36.83px 0 36.81px;
    display: flex;
    position: absolute;
    top: 84px;
    left: 0;
  }

  .iphone .container-5 {
    flex-direction: column;
    align-items: center;
    width: 300px;
    padding: 0 34.54px 0 34.55px;
    display: flex;
    position: absolute;
    top: 108px;
    left: 0;
  }

  .iphone .frame-40 {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 29px;
    left: 135px;
  }

  .iphone .container-6 {
    flex-direction: column;
    align-items: center;
    width: 300px;
    padding: 0 67.6px 0 67.59px;
    display: flex;
    position: absolute;
    top: 84px;
    left: 0;
  }

  .iphone .container-7 {
    flex-direction: column;
    align-items: center;
    width: 300px;
    padding: 0 56.5px 0 56.48px;
    display: flex;
    position: absolute;
    top: 108px;
    left: 0;
  }

  .iphone .frame-41 {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 30px;
    left: 135px;
  }

  .iphone .container-8 {
    flex-direction: column;
    align-items: center;
    width: 300px;
    padding: 0 94.59px 0 94.58px;
    display: flex;
    position: absolute;
    top: 84px;
    left: 0;
  }

  .iphone .text-wrapper-25 {
    color: #f4f0ff;
    letter-spacing: 0;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
    margin-top: -1px;
    margin-left: -18.08px;
    margin-right: -18.08px;
    font-family: Inter, Helvetica;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    position: relative;
  }

  .iphone .container-9 {
    flex-direction: column;
    align-items: center;
    width: 300px;
    padding: 0 88.52px 0 88.5px;
    display: flex;
    position: absolute;
    top: 108px;
    left: 0;
  }

  .iphone .text-wrapper-26 {
    color: #efedfd99;
    letter-spacing: 0;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
    margin-top: -1px;
    margin-left: -44.51px;
    margin-right: -44.51px;
    font-family: Inter, Helvetica;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    position: relative;
  }

  .iphone .frame-42 {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 32px;
    left: 135px;
  }

  .iphone .overlap-6 {
    width: 390px;
    height: 442px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .iphone .frame-43 {
    width: 390px;
    height: 209px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .iphone .blur-2 {
    mix-blend-mode: screen;
    width: 390px;
    height: 442px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .iphone .group-2 {
    z-index: 1000;
    width: auto;
    max-width: 120px;
    height: 32px;
    position: fixed;
    top: 38px;
    left: 10px;
    transform: none;
  }

  .iphone .container-10 {
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 388px;
    padding: 0 20px;
    display: flex;
    position: absolute;
    top: 115px;
    left: 0;
  }

  .iphone .save-time-and-money sup {
    vertical-align: top;
    font-size: .65em;
    line-height: 1;
  }

  .iphone .save-time-and-money {
    color: #d9d9d9;
    letter-spacing: -1.6px;
    text-align: center;
    align-self: stretch;
    margin-top: -1px;
    font-family: Switzer-Medium, Helvetica;
    font-size: 36px;
    font-weight: 500;
    line-height: normal;
    position: relative;
  }

  .iphone .text-wrapper-27 {
    color: #b4bcd0;
    letter-spacing: 0;
    text-align: center;
    align-self: stretch;
    font-family: Switzer-Regular, Helvetica;
    font-size: 16px;
    font-weight: 400;
    line-height: 28.6px;
    position: relative;
  }

  .iphone .follow {
    background-color: #fff;
    border-radius: 8px;
    align-items: center;
    gap: 10px;
    height: 38px;
    padding: 8px 15px;
    display: inline-flex;
    position: relative;
  }

  .iphone .follow a {
    color: inherit;
    align-items: center;
    gap: 10px;
    text-decoration: none;
    display: inline-flex;
  }

  .iphone .border {
    border: 1px solid #f3eeff14;
    border-radius: 8px;
    width: 122px;
    height: 38px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .iphone .SVG {
    width: 16px;
    height: 16px;
    position: relative;
  }

  .iphone .text-wrapper-28 {
    -webkit-text-fill-color: transparent;
    color: #0000;
    letter-spacing: 0;
    text-align: center;
    white-space: nowrap;
    background: linear-gradient(#121340 0%, #6c53b7 100%) text;
    width: fit-content;
    font-family: Switzer-Medium, Helvetica;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    position: relative;
    -webkit-background-clip: text !important;
  }

  .iphone .iphone-pro-mockup {
    transform-origin: center;
    width: 100%;
    height: auto;
    position: absolute;
    top: 500px;
    left: auto;
    transform: scale(1.7);
  }

  .iphone .frame-44 {
    flex-direction: column;
    align-items: flex-start;
    gap: 55px;
    width: 390px;
    display: flex;
    position: absolute;
    top: 900px;
    left: 0;
  }

  .iphone .container-11 {
    flex-direction: column;
    flex: none;
    align-self: stretch;
    align-items: center;
    gap: 20px;
    width: 100%;
    max-width: 1088px;
    padding: 0 20px;
    display: flex;
    position: relative;
  }

  .iphone .overlay-shadow {
    backdrop-filter: blur(6px) brightness();
    background-color: #ffffff01;
    border-radius: 32px;
    flex-wrap: nowrap;
    flex: none;
    align-items: center;
    padding: 6px 14px 6px 15px;
    display: inline-flex;
    position: relative;
    overflow: hidden;
    box-shadow: inset 0 -7px 11px #a48fff1f;
  }

  .iphone .container-12 {
    flex-direction: column;
    flex: none;
    align-items: flex-start;
    display: inline-flex;
    position: relative;
  }

  .iphone .text-wrapper-29 {
    color: #fff;
    letter-spacing: 0;
    white-space: nowrap;
    width: auto;
    margin-top: -1px;
    font-family: Inter, Helvetica;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    position: relative;
  }

  .iphone .mask-group {
    object-fit: cover;
    width: 97px;
    height: 32px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .iphone .text-wrapper-30 {
    color: #f7f8f8;
    letter-spacing: -1.12px;
    text-align: center;
    align-self: stretch;
    font-family: Switzer-Medium, Helvetica;
    font-size: 36px;
    font-weight: 500;
    line-height: 56px;
    position: relative;
  }

  .iphone .container-13 {
    flex-direction: column;
    flex: none;
    justify-content: center;
    align-self: stretch;
    align-items: flex-start;
    gap: 16.59px;
    width: 100%;
    max-width: 752px;
    padding: 0 22.12px;
    display: flex;
    position: relative;
  }

  .iphone .frame-45 {
    width: 346px;
    height: 330px;
    position: relative;
    overflow: hidden;
  }

  .iphone .overlap-7 {
    height: 724px;
    position: relative;
  }

  .iphone .background {
    background: radial-gradient(50% 50%, #6663f6 0%, #0000 100%);
    border-radius: 33.18px;
    flex-direction: column;
    align-items: flex-start;
    width: 346px;
    padding: 22.12px 33.18px 215.19px;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
  }

  .iphone .frame-46 {
    flex-direction: column;
    flex: none;
    align-self: stretch;
    align-items: flex-start;
    gap: 20px;
    width: 100%;
    display: flex;
    position: relative;
  }

  .iphone .text-wrapper-31 {
    color: #f7f8f8;
    letter-spacing: -.17px;
    align-self: stretch;
    margin-top: -1px;
    font-family: Switzer-Medium, Helvetica;
    font-size: 21px;
    font-weight: 500;
    line-height: 21.6px;
    position: relative;
  }

  .iphone .text-wrapper-32 {
    color: #b4bcd0;
    letter-spacing: 0;
    align-self: stretch;
    font-family: Switzer-Medium, Helvetica;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.6px;
    position: relative;
  }

  .iphone .mask-group-2 {
    object-fit: cover;
    width: 346px;
    height: 329px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .iphone .archive-2 {
    backdrop-filter: blur(20.74px) brightness();
    background: linear-gradient(#12134080 0%, #2f31a600 100%);
    border-color: #0000;
    border-image: linear-gradient(#fff0, #fff3 50.5%, #fff0 100%) 1;
    border-radius: 35px;
    justify-content: center;
    align-items: flex-start;
    width: 279px;
    height: 586px;
    display: flex;
    position: absolute;
    top: 138px;
    left: 33px;
    overflow: hidden;
  }

  .iphone .frame-47 {
    object-fit: contain;
    width: 100%;
    height: auto;
    max-height: 100%;
  }

  .iphone .frame-68 {
    width: 346px;
    height: 334px;
    position: relative;
    overflow: hidden;
  }

  .iphone .overlap-8 {
    height: 722px;
    position: relative;
    top: 7px;
  }

  .iphone .mask-group-3 {
    object-fit: cover;
    width: 346px;
    height: 327px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .iphone .archive-3 {
    backdrop-filter: blur(20.74px) brightness();
    background: linear-gradient(#12134080 0%, #2f31a600 100%);
    border-color: #0000;
    border-image: linear-gradient(#fff0, #fff3 50.5%, #fff0 100%) 1;
    border-radius: 35px;
    justify-content: center;
    align-items: flex-start;
    width: 279px;
    height: 586px;
    display: flex;
    position: absolute;
    top: 136px;
    left: 33px;
    overflow: hidden;
  }

  .iphone .frame-72 {
    object-fit: contain;
    width: 100%;
    height: auto;
    max-height: 100%;
  }

  .iphone .container-14 {
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 390px;
    padding: 0 20px;
    display: flex;
    position: absolute;
    top: 2000px;
    left: 0;
  }

  .iphone .mask-group-4 {
    object-fit: cover;
    width: 88px;
    height: 32px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .iphone .text-wrapper-39 {
    -webkit-text-fill-color: transparent;
    color: #0000;
    letter-spacing: 0;
    text-align: center;
    background: linear-gradient(#fff 0%, #ffffffb3 100%) text;
    align-self: stretch;
    font-family: Switzer-Regular, Helvetica;
    font-size: 36px;
    font-weight: 400;
    line-height: normal;
    position: relative;
    -webkit-background-clip: text !important;
  }

  .iphone .text-wrapper-40 {
    color: #efedfdb2;
    letter-spacing: 0;
    text-align: center;
    align-self: stretch;
    font-family: Inter, Helvetica;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    position: relative;
  }

  .iphone .overlap-9 {
    width: 310px;
    height: 672px;
    position: absolute;
    top: 3900px;
    left: 40px;
  }

  .iphone .text-wrapper-41 {
    color: #8f8f9f;
    letter-spacing: -.62px;
    width: 309px;
    font-family: Switzer-Semibold, Helvetica;
    font-size: 32px;
    font-weight: 400;
    line-height: normal;
    position: absolute;
    top: 0;
    left: 0;
  }

  .iphone .text-wrapper-42 {
    color: #fff;
    letter-spacing: -.62px;
    width: 309px;
    height: 294px;
    font-family: Switzer-Semibold, Helvetica;
    font-size: 32px;
    font-weight: 400;
    line-height: normal;
    position: absolute;
    top: 0;
    left: 0;
  }

  .iphone .background-2 {
    background: radial-gradient(50% 50%, #0b0a3b 0%, #0b0a3b36 100%);
    border-radius: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 350px;
    padding: 20px 0;
    display: flex;
    position: absolute;
    top: 4700px;
    left: 20px;
  }

  .iphone .container-wrapper {
    flex-direction: column;
    flex: none;
    align-self: stretch;
    align-items: flex-start;
    width: 100%;
    display: flex;
    position: relative;
  }

  .iphone .container-15 {
    flex-direction: column;
    flex: none;
    align-self: stretch;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
    padding: 0 0 0 20px;
    display: flex;
    position: relative;
  }

  .iphone .mask-group-5 {
    object-fit: cover;
    width: 97px;
    height: 32px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .iphone .container-16 {
    flex-direction: column;
    flex: none;
    align-self: stretch;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
    display: flex;
    position: relative;
  }

  .iphone .text-wrapper-43 {
    color: #f1f1ef;
    letter-spacing: 0;
    width: 290px;
    margin-top: -1px;
    font-family: Switzer-Semibold, Helvetica;
    font-size: 36px;
    font-weight: 400;
    line-height: normal;
    position: relative;
  }

  .iphone .text-wrapper-44 {
    color: #adadad;
    letter-spacing: -.18px;
    align-self: stretch;
    font-family: Switzer-Regular, Helvetica;
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
    position: relative;
  }

  .iphone .group-wrapper {
    flex: none;
    align-self: stretch;
    width: 100%;
    position: relative;
    overflow: hidden;
  }

  .iphone .group-3 {
    width: 553px;
    height: 235px;
    position: relative;
    top: 41px;
    left: 3px;
  }

  .iphone .overlap-10 {
    width: 553px;
    height: 234px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .iphone .group-4 {
    width: 552px;
    height: 234px;
    position: absolute;
    top: 0;
    left: 1px;
  }

  .iphone .overlap-group-7 {
    background-image: url("group-15.0b11acfa.png");
    background-size: 100% 100%;
    width: 327px;
    height: 222px;
    position: relative;
  }

  .iphone .text-wrapper-45 {
    color: #fff;
    letter-spacing: 0;
    width: 245px;
    font-family: Switzer-Medium, Helvetica;
    font-size: 16px;
    font-weight: 500;
    line-height: 17.6px;
    position: absolute;
    top: 33px;
    left: 40px;
  }

  .iphone .made-simple {
    width: 96px;
    height: 15px;
    position: absolute;
    top: 50px;
    left: 41px;
  }

  .iphone .group-5 {
    width: 325px;
    height: 221px;
    position: absolute;
    top: 1px;
    left: 3px;
  }

  .iphone .group-6 {
    width: 10px;
    height: 3px;
    position: absolute;
    top: 76px;
    left: 42px;
  }

  .iphone .group-7 {
    width: 11px;
    height: 3px;
    position: absolute;
    top: 107px;
    left: 42px;
  }

  .iphone .group-8 {
    width: 11px;
    height: 3px;
    position: absolute;
    top: 137px;
    left: 42px;
  }

  .iphone .group-9 {
    width: 11px;
    height: 3px;
    position: absolute;
    top: 168px;
    left: 42px;
  }

  .iphone .group-10 {
    width: 262px;
    height: 1px;
    position: absolute;
    top: 78px;
    left: 66px;
  }

  .iphone .group-11 {
    width: 262px;
    height: 1px;
    position: absolute;
    top: 108px;
    left: 66px;
  }

  .iphone .group-12 {
    width: 262px;
    height: 1px;
    position: absolute;
    top: 138px;
    left: 66px;
  }

  .iphone .group-13 {
    width: 262px;
    height: 1px;
    position: absolute;
    top: 170px;
    left: 66px;
  }

  .iphone .group-14 {
    width: 262px;
    height: 108px;
    position: absolute;
    top: 67px;
    left: 66px;
  }

  .iphone .group-15 {
    width: 258px;
    height: 91px;
    position: absolute;
    top: 84px;
    left: 70px;
  }

  .iphone .group-16 {
    width: 2px;
    height: 25px;
    position: absolute;
    top: 11px;
    left: 0;
  }

  .iphone .group-17 {
    width: 326px;
    height: 81px;
    position: absolute;
    top: 140px;
    left: 2px;
  }

  .iphone .clip-path-group {
    width: 12px;
    height: 10px;
    position: absolute;
    top: 43px;
    left: 191px;
  }

  .iphone .group-18 {
    width: 46px;
    height: 10px;
    position: absolute;
    top: 12px;
    left: 39px;
  }

  .iphone .group-19 {
    width: 45px;
    height: 10px;
    position: absolute;
    top: 87px;
    left: 299px;
  }

  .iphone .group-20 {
    width: 2px;
    height: 66px;
    position: absolute;
    top: 108px;
    left: 321px;
  }

  .iphone .group-21 {
    width: 36px;
    height: 18px;
    position: absolute;
    top: 99px;
    left: 304px;
  }

  .iphone .text-wrapper-46 {
    color: #fff;
    letter-spacing: 0;
    opacity: .5;
    width: 24px;
    font-family: Switzer-Regular, Helvetica;
    font-size: 4.7px;
    font-weight: 400;
    line-height: 5.1px;
    position: absolute;
    top: 15px;
    left: 55px;
  }

  .iphone .overlap-11 {
    width: 49px;
    height: 15px;
    position: absolute;
    top: -4183px;
    left: -1312px;
  }

  .iphone .group-22 {
    width: 47px;
    height: 15px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .iphone .group-23 {
    width: 47px;
    height: 14px;
    position: absolute;
    top: 0;
    left: 1px;
  }

  .iphone .container-17 {
    flex-direction: column;
    align-items: center;
    gap: 36px;
    width: 385px;
    padding: 0 20px;
    display: flex;
    position: absolute;
    top: 5520px;
    left: 0;
  }

  .iphone .icon {
    background: linear-gradient(#6c53b7 0%, #121340 78%, #070722 100%);
    border-radius: 14.25px;
    width: 62.64px;
    height: 62.64px;
    position: relative;
  }

  .iphone .union {
    width: 23px;
    height: 31px;
    position: absolute;
    top: 16px;
    left: 20px;
  }

  .iphone .upgrade-to-the-new {
    -webkit-text-fill-color: transparent;
    color: #0000;
    letter-spacing: -.8px;
    text-align: center;
    background: radial-gradient(50% 50%, #fff9 0%, #fff 100%) text;
    align-self: stretch;
    font-family: Switzer-Medium, Helvetica;
    font-size: 32px;
    font-weight: 500;
    line-height: normal;
    position: relative;
    -webkit-background-clip: text !important;
  }

  .iphone .follow-2 {
    background: linear-gradient(#f3eeff00 0%, #f3eeff0a 100%);
    border: 1px solid #f3eeff14;
    border-radius: 8px;
    align-items: center;
    gap: 10px;
    width: 127px;
    height: 38px;
    padding: 8px 15px 8px 10px;
    display: flex;
    position: relative;
    top: 5830px;
    left: 33%;
    box-shadow: inset 0 0 12px #ffffff14;
  }

  .iphone .image {
    object-fit: contain;
    width: 21px;
    height: 22px;
    margin-left: -1px;
    display: inline-block;
  }

  .iphone .text-wrapper-47 {
    color: #fff;
    text-align: center;
    width: auto;
    margin-right: -5px;
    font-family: Switzer-Medium, Helvetica;
    font-size: 14px;
    font-weight: 500;
  }

  .iphone .text-wrapper-47 a {
    color: #efedfd;
    text-decoration: none;
  }

  .iphone .text-wrapper-47 a:hover {
    text-decoration: underline;
  }

  .iphone .frame-84 {
    flex-direction: column;
    align-items: flex-start;
    width: 265px;
    display: flex;
    position: absolute;
    top: 6039px;
    left: 19px;
  }

  .iphone .div-wrapper-2 {
    flex-direction: column;
    flex: none;
    align-items: flex-start;
    padding: 0 16px 0 0;
    display: inline-flex;
    position: relative;
  }

  .iphone .text-wrapper-48 {
    color: #efedfd99;
    letter-spacing: 0;
    white-space: nowrap;
    width: fit-content;
    margin-top: -1px;
    font-family: Switzer-Regular, Helvetica;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    position: relative;
  }

  .iphone a {
    color: #fff;
    text-decoration: none;
  }

  .iphone a:visited {
    color: inherit;
  }

  .iphone a:hover {
    text-decoration: underline;
  }

  .iphone a:active {
    color: inherit;
  }

  .iphone .text-wrapper-49 {
    color: #efedfd;
    letter-spacing: 0;
    width: 197px;
    height: 11px;
    font-family: Switzer-Regular, Helvetica;
    font-size: 21.9px;
    font-weight: 400;
    line-height: 11px;
    position: absolute;
    top: 5997px;
    left: 19px;
  }

  .iphone .text-wrapper-49 a {
    color: #efedfd;
    text-decoration: none;
  }

  .iphone .text-wrapper-49 a:hover {
    text-decoration: underline;
  }

  .iphone .logo {
    width: 129px;
    height: 27px;
    position: absolute;
    top: 5938px;
    left: 19px;
  }

  .iphone .follow-99 {
    z-index: 1000;
    background: linear-gradient(#f3eeff00 0%, #f3eeff0a 100%);
    border-radius: 8px;
    align-items: center;
    gap: 10px;
    width: auto;
    max-width: calc(100% - 40px);
    height: 42px;
    padding: 10px;
    display: inline-flex;
    position: fixed;
    top: 32px;
    bottom: 20px;
    right: 10px;
    overflow: hidden;
    box-shadow: inset 0 0 12px #ffffff14;
  }

  .iphone .follow-99 a {
    color: inherit;
    align-items: center;
    gap: 10px;
    text-decoration: none;
    display: inline-flex;
  }

  .iphone .apple-icon-99 {
    width: 16px;
    height: 16px;
    position: relative;
  }

  .iphone .text-wrapper-99 {
    color: #fff;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
    margin-right: 7px;
    font-family: Switzer-Medium, Helvetica;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  .iphone .language-toggle-mobile {
    z-index: 1000;
    background: linear-gradient(#f3eeff00 0%, #f3eeff0a 100%);
    border: 1px solid #f3eeff14;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    height: 38px;
    padding: 8px 10px;
    display: flex;
    position: absolute;
    top: 32px;
    left: 50%;
    transform: translate(-50%);
    box-shadow: inset 0 0 12px #ffffff14;
  }

  .iphone .language-toggle-mobile button {
    color: #fff;
    cursor: pointer;
    text-align: center;
    white-space: nowrap;
    background: none;
    border: none;
    font-family: Switzer-Medium, Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
  }

  html, body {
    background-color: #000212;
    height: 100%;
    margin: 0;
    padding: 0;
  }
}

.PageWrapper {
  background-color: #000212;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.PageWrapper .div {
  z-index: 0;
  background-color: #000212;
  width: 100%;
  height: auto;
  padding-bottom: 2rem;
  position: relative;
  overflow-x: hidden;
}

.PageWrapper .overlap-8 {
  object-fit: cover;
  width: 100%;
  max-width: none;
  height: 1173px;
  position: absolute;
  top: 0;
  left: 0;
}

.PageWrapper .frame-31 {
  object-fit: cover;
  width: 100%;
  max-width: none;
  height: 506px;
  position: absolute;
  top: 0;
  left: 0;
}

.PageWrapper .blur-2 {
  object-fit: cover;
  mix-blend-mode: screen;
  width: 100%;
  max-width: none;
  height: 737px;
  position: absolute;
  top: 0;
  left: 0;
}

.PageWrapper .border {
  z-index: -1;
  border: 1px solid #f3eeff14;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.PageWrapper .SVG {
  width: 16px;
  height: 16px;
  position: relative;
}

.PageWrapper .text-wrapper-6 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  font-family: Switzer-Medium, Helvetica;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  position: relative;
}

.PageWrapper .container-22 {
  z-index: 10;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  width: 100%;
  max-width: 90%;
  margin: 4rem auto;
  padding: 2rem 1rem;
  display: flex;
  position: relative;
}

.PageWrapper .heading-2 {
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 2rem;
  padding: 0 2rem;
  display: flex;
  position: relative;
}

.PageWrapper .title {
  color: #f7f8f8;
  letter-spacing: -.05em;
  text-align: center;
  white-space: nowrap;
  margin-top: .5rem;
  font-family: Switzer-Medium, Helvetica;
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.2;
}

.PageWrapper .content {
  color: #b4bcd0;
  text-align: center;
  max-width: 90%;
  margin: 0 auto;
  font-family: Switzer-Regular, Helvetica;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

.PageWrapper .frame-61 {
  box-sizing: border-box;
  width: calc(100% - 2rem);
  margin: 4rem auto 0;
  padding: 1rem;
  position: relative;
}

.PageWrapper .container-30 {
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  display: flex;
}

.PageWrapper .list-wrapper {
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
  display: flex;
  position: relative;
}

.PageWrapper .list {
  flex-flow: wrap;
  justify-content: center;
  gap: .5rem;
  display: flex;
}

.PageWrapper .div-wrapper-2 {
  flex-direction: column;
  align-items: flex-start;
  padding: 0 16px;
  display: flex;
  position: relative;
}

.PageWrapper .text-wrapper-51 {
  color: #efedfd99;
  text-align: center;
  width: 100%;
  font-family: Switzer-Regular, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.PageWrapper a {
  color: inherit;
  text-decoration: none;
}

.PageWrapper a:visited {
  color: inherit;
}

.PageWrapper a:hover {
  text-decoration: underline;
}

.PageWrapper a:active {
  color: inherit;
}

.PageWrapper .follow-5 {
  z-index: 1000;
  background: linear-gradient(#f3eeff00 0%, #f3eeff0a 100%);
  border-radius: 8px;
  align-items: center;
  gap: 10px;
  width: auto;
  max-width: calc(100% - 40px);
  height: 38px;
  padding: 8px 15px;
  display: inline-flex;
  position: fixed;
  top: 32px;
  right: 20%;
  overflow: hidden;
  box-shadow: inset 0 0 12px #ffffff14;
}

.PageWrapper .follow-5 a {
  color: inherit;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  display: inline-flex;
}

.PageWrapper .group-21 {
  z-index: 1000;
  width: 153px;
  height: 32px;
  position: fixed;
  top: 32px;
  left: 20%;
  transform: none;
}

.PageWrapper .language-toggle-mobile {
  z-index: 1000;
  background: linear-gradient(#f3eeff00 0%, #f3eeff0a 100%);
  border: 1px solid #f3eeff14;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  height: 38px;
  padding: 8px 10px;
  display: flex;
  position: absolute;
  top: 32px;
  left: 50%;
  transform: translate(-50%);
  box-shadow: inset 0 0 12px #ffffff14;
}

.PageWrapper .language-toggle-mobile button {
  color: #fff;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  background: none;
  border: none;
  font-family: Switzer-Medium, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.PageWrapper .content-section p:first-of-type {
  margin-bottom: 1rem;
}

.PageWrapper .content-section {
  color: #b4bcd0;
  text-align: left;
  box-sizing: border-box;
  max-width: 800px;
  margin: 0 auto;
  padding: 1rem;
  font-family: Switzer-Regular, Helvetica;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

.PageWrapper .content-section h2 {
  color: #f7f8f8;
  letter-spacing: 0;
  margin-top: 2rem;
  font-family: Switzer-Medium, Helvetica;
  font-size: 20.8px;
  font-weight: 500;
}

.PageWrapper .content-section ul {
  color: #b4bcd0;
  margin-left: 1.5rem;
  padding-left: 1rem;
  font-family: Switzer-Regular, Helvetica;
  font-size: 1rem;
  list-style-type: disc;
}

.PageWrapper .content-section ul li {
  margin-bottom: .5rem;
}

.PageWrapper .content-section li {
  color: #b4bcd0;
  margin-bottom: .5rem;
  font-family: Switzer-Regular, Helvetica;
  font-size: 1rem;
}

.PageWrapper .content-section .email-link {
  color: #fff;
  font-weight: 500;
  text-decoration: none;
}

.PageWrapper .content-section .email-link:hover {
  color: #fff;
  text-decoration: underline;
}

.PageWrapper .content-section .email-link:active {
  color: #fff;
}

.PageWrapper .content-section .privacy-policy-link {
  color: #fff;
  font-weight: 500;
  text-decoration: none;
}

.PageWrapper .content-section .privacy-policy-link:hover {
  color: #fff;
  text-decoration: underline;
}

.PageWrapper .content-section .privacy-policy-link:active {
  color: #fff;
}

@media (width <= 1440px) {
  .PageWrapper .overlap-8, .PageWrapper .frame-31, .PageWrapper .blur-2 {
    width: 100%;
    height: auto;
  }
}

@media (width <= 1024px) {
  .PageWrapper .div {
    padding: 1rem 1rem 1.5rem;
  }

  .PageWrapper .overlap-8 {
    width: 1440px;
    height: 1173px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .PageWrapper .frame-31 {
    width: 1440px;
    height: 506px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .PageWrapper .blur-2 {
    mix-blend-mode: screen;
    width: 1440px;
    height: 737px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .PageWrapper .frame-61 {
    width: calc(100% - 2rem);
    margin: 3rem auto 0;
  }

  .PageWrapper .container-22 {
    margin: 0 auto 3rem;
  }

  .PageWrapper .container-30 {
    gap: 1.5rem;
  }

  .PageWrapper .list {
    flex-wrap: wrap;
    justify-content: center;
    gap: 12px;
  }

  .PageWrapper .text-wrapper-51 {
    font-size: 13px;
    line-height: 18px;
  }

  .PageWrapper .title {
    white-space: normal;
    font-size: 2rem;
  }

  .PageWrapper .language-toggle-mobile {
    top: 40px;
  }
}

@media (width <= 768px) {
  .PageWrapper .div {
    padding: 1rem;
  }

  .PageWrapper .overlap-8 {
    width: 1440px;
    height: 1173px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .PageWrapper .frame-31 {
    width: 1440px;
    height: 506px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .PageWrapper .blur-2 {
    mix-blend-mode: screen;
    width: 1440px;
    height: 737px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .PageWrapper .container-22 {
    margin: 30px auto 2.5rem;
    padding: 1rem .5rem;
  }

  .PageWrapper .frame-61 {
    align-items: center;
    width: 100%;
    margin: 2.5rem auto 0;
    padding: 1rem .5rem;
  }

  .PageWrapper .list-wrapper {
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;
    display: flex;
    position: relative;
  }

  .PageWrapper .list {
    flex-direction: column;
    align-items: center;
    gap: .5rem;
  }

  .PageWrapper .privacy-policy-link, .PageWrapper .terms-and-conditions-link {
    font-size: 12px;
  }

  .PageWrapper .container-30 {
    flex-direction: column;
    align-items: center;
    padding: 20px 0 40px;
  }

  .PageWrapper .text-wrapper-51 {
    font-size: 12px;
    line-height: 16px;
  }

  .PageWrapper .title {
    white-space: normal;
    font-size: 1.8rem;
  }

  .PageWrapper .language-toggle-mobile {
    top: 35px;
  }

  .PageWrapper .follow-5 {
    width: auto;
    height: 42px;
    padding: 10px;
    bottom: 20px;
    right: 10px;
  }

  .PageWrapper .group-21 {
    width: auto;
    max-width: 120px;
    top: 38px;
    left: 10px;
  }

  .blogs-overview {
    max-width: 90%;
    margin: 0 auto;
    padding: 1rem;
  }

  .blogs-list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .blog-item {
    margin-bottom: 1.5rem;
    padding-bottom: 1rem;
  }

  .blog-link {
    color: inherit;
    text-decoration: none;
    display: block;
  }

  .blog-link h2 {
    margin: 0;
    font-size: 1.5rem;
  }

  .blog-link p {
    margin: .5rem 0;
    font-size: 1rem;
  }

  .blog-link .blog-date {
    color: #777;
    font-size: .9rem;
  }

  @media (width <= 768px) {
    .blogs-overview {
      max-width: 95%;
    }

    .blog-link h2 {
      font-size: 1.2rem;
    }

    .blog-link p {
      font-size: .9rem;
    }
  }
}

.cookie-container {
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  display: flex;
}

.cookie-container button {
  margin: 10px;
}

@media (width <= 480px) {
  .cookie-container {
    flex-direction: row;
    justify-content: center;
  }

  .cookie-container button {
    width: auto;
    margin: 10px;
  }
}
/*# sourceMappingURL=index.9ef1148e.css.map */
